import React, { useState, useEffect, useRef } from 'react';
import "./deducted.scss";
import ReactDOM from 'react-dom';
import { format} from '../format-number';
import { useTranslate } from '../translate/translate';
import LevelupModal from './levelup';
import xpImage from '../port/xp.png'

const DeductalModal = ({ deductalModal, setDeductalModal, resourceImages, endVoyage, changeModals, levelupModal, setLevelupModal, setLVL, setXP }) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);


    const voyageEndFunc = async () => {
        setLoading(true);
        try {
            const endVoyageResp = await endVoyage(window.tgID, deductalModal.adjustedResources, deductalModal.voyage);
            changeModals(deductalModal.modalId);

            let prevLvl = Math.floor((-1 + Math.sqrt(1 + (8 * window.xp) / 10)) / 2) + 1;
            let nowLvl = Math.floor((-1 + Math.sqrt(1 + (8 * endVoyageResp.xp) / 10)) / 2) + 1;

            setXP(endVoyageResp.xp);
            setLVL(endVoyageResp.lvl);
            if (nowLvl > prevLvl) {
                setLVL(nowLvl);
                setLevelupModal(nowLvl);
            }
            setDeductalModal(null);
        } finally {
            setLoading(false);
        }
    };

    const allAmountsAreZero = deductalModal && deductalModal.adjustedResources.every(amount => amount <= 0);

    return (
        <>
            {levelupModal && <LevelupModal levelupModal={levelupModal} setLevelupModal={setLevelupModal} />}
            {deductalModal ? (
                ReactDOM.createPortal(
                    <div className="deductal-store-overlayy">
                        <div className="box">
                            <div className="title">{t('voyage-full1')}</div>
                            <div className="text1">{t('deducted1')}</div>
                            <div className="resources-box">
                                <span className="title-res">{t('deducted2')}</span>
                                <div className="resources">
                                    {!allAmountsAreZero ? (
                                        deductalModal.adjustedResources.map((amount, index) => {
                                            if (amount > 0) {
                                                return (
                                                    <div key={index} className="resource">
                                                        <div className="image">
                                                            <img src={resourceImages[index+1]} alt={`Resource ${index+1}`} />
                                                        </div>
                                                        <span className="resource-amount">{format(amount)}</span>
                                                    </div>
                                                );
                                            }
                                            return;
                                        })
                                    ) : (
                                        <div className="resource">
                                            <div className="image xp">
                                                <img src={xpImage} alt='XP' />
                                            </div>
                                            <span className="resource-amount">{deductalModal.xp}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button className={`deductal-button ${(loading) ? 'disabled' : ''}`} onClick={() => voyageEndFunc()}>
                                {loading ? (
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) : 
                                    <span>{t('ok')}</span>
                                }
                            </button>
                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default DeductalModal;