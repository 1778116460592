import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguageRequest, getLanguageRequest } from '../requests';

import LangIconEn from '../translate/en.png';
import LangIconRu from '../translate/ru.png';

const LanguageButton = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const fetchLanguage = async () => {
      try {
        if (window.lang == 'ru' || window.lang == 'en') {
          i18n.changeLanguage(window.lang);
          setCurrentLanguage(window.lang);
        } else {
          const language = await getLanguageRequest(window.tgID);
          i18n.changeLanguage(language);
          setCurrentLanguage(language);
        }
      } catch (error) {
        console.error('Error fetching language:', error);
      }
    };

    fetchLanguage();
  }, [i18n, window.lang]);

  const changeLanguage = async (language) => {
    try {
      i18n.changeLanguage(language);
      setCurrentLanguage(language);

      setLanguage({ language: language });

      // Сохранение языка в базу данных
      await changeLanguageRequest(window.tgID);
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  return (
      <button id="lang-button" className="profile-button" onClick={() => changeLanguage(currentLanguage === 'en' ? 'ru' : 'en')}>
        <img src={currentLanguage === 'en' ? LangIconEn : LangIconRu} alt="Language" />
      </button>
  );
};

export default LanguageButton;