import React, { useState, useEffect } from 'react';
import "./select-modal.scss";
import ReactDOM from 'react-dom';
import { setUserCoins } from '../requests';
import { useTranslate } from '../translate/translate';
import { format, formatMarket } from '../format-number';
import CoinImage from '../navigation/airdrop.png';

const resourceCoins = {
    1: 0.01,
    2: 0.1,
    3: 0.5,
    4: 0.75,
    5: 1,
    6: 1.5,
    7: 2,
    8: 3,
    9: 5,
    10: 7.5,
    11: 10,
    12: 15,
    13: 20,
    14: 30
};

const componentCoins = {
    1: 15,
    2: 20,
    3: 25,
    4: 30,
    5: 35,
    6: 40,
    7: 70,
    8: 80,
    9: 100,
    10: 120,
    11: 150,
    12: 450,
    13: 550,
    14: 650,
    15: 800,
    16: 2500,
    17: 3000,
    18: 3500,
    19: 15000,
    20: 17500,
    21: 50000
};

const SelectModal = ({ resourceImages, componentImages, selectResource, setSelectResource, selectComponent, setSelectComponent, setResources, setComponents, setCoins, setAlert }) => {

    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [placeholder, setPlaceholder] = useState();
    const [coinsAdd, setCoinsAdd] = useState(0);
    const [showPyro, setShowPyro] = useState(false);

    const selectResourceAmount = window.resources[selectResource-1] - window.resources_market[selectResource-1];
    const selectComponentAmount = window.components[selectComponent-1] - window.components_market[selectComponent-1];

    const inputChange = (action) => {
        if (action === 'plus') {
            if (count + 1 <= (selectResource ? selectResourceAmount : selectComponentAmount)) {
                setCount(prevCount => prevCount + 1);
            }
        } else if (action === 'minus') {
            if (count - 1 < 0) {
                setCount(0);
            } else {
                setCount(prevCount => prevCount - 1);
            }
        }
    };

    const close = () => {
        setSelectResource(null)
        setSelectComponent(null)
    }

    const handleChange = (value) => {
        // Проверяем, что значение состоит только из цифр
        if (/^[1-9]\d*$/.test(value) || value === '') {
            // Преобразуем значение в число
            const numericValue = Number(value);
    
            // Проверяем, что значение не превышает доступное количество ресурсов или компонентов
            if (numericValue > (selectResource ? selectResourceAmount : selectComponentAmount)) {
                setCount((selectResource ? selectResourceAmount : selectComponentAmount).toString());
            } else {
                setCount(value);
            }
        }
    };

    useEffect(() => {
        if (selectResource) {
            setCoinsAdd((count * resourceCoins[selectResource]).toFixed(2));
        } else if (selectComponent) {
            setCoinsAdd((count * componentCoins[selectComponent]).toFixed(2));
        }
    }, [count]);

    const earnCoins = async () => {
        setLoading(true);

        let type, number;

        if (selectResource) {
            type = 'Resource';
            number = selectResource-1;
        } else if (selectComponent) {
            type = 'Component';
            number = selectComponent-1;
        }

        // tgID, type, number, amount, coins
        const setCoinsResp = await setUserCoins(window.tgID, type, number, +count, coinsAdd);

        if (setCoinsResp === 'True') {
            // Обновить ресурсы/компоненты и монеты
            setResources(window.resources.map((value, index) => value - window.resources_market[index]));
            setComponents(window.components.map((value, index) => value - window.components_market[index]));
            setCoins(window.coins);

            new Promise((resolve) => {
                // Alert
                setAlert('coins-add');
                resolve();
            })
            .then(() => {
                // Феерверк
                setShowPyro(true);
                return new Promise((resolve) => setTimeout(() => {
                    setShowPyro(false);
                    resolve();
                }, 1000));
            })
            .then(() => {
                // Завершить загрузку
                setLoading(false);
                // Закрыть модалку
                close();
            });
        } 
    }

    return (
        <>     
            {selectResource || selectComponent ? (
                ReactDOM.createPortal(
                    <div className="select-modal-overlay" onClick={() => (!loading && close())}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('select')}</div>

                            <div className="item">

                                {showPyro && (
                                    <div className="pyro">
                                        <div className="before"></div>
                                        <div className="after"></div>
                                    </div>
                                )}

                                <div className="item-left">
                                    <div className="img-box"> 
                                        {selectResource ? 
                                            (<img src={resourceImages[selectResource]} alt={`Resource ${selectResource}`} />) :
                                            (<img src={componentImages[selectComponent]} alt={`Component ${selectComponent}}`} />)
                                        }
                                        <span>{selectResource ? 
                                            format(selectResourceAmount) : 
                                            formatMarket(selectComponentAmount)}
                                        </span>
                                    </div>
                                </div>

                                <div className="item-right">

                                    <div className="sell-bar">
                                        <div className="sell-bar-fill" style={{ width: `${(count || 0) / (selectResource ? selectResourceAmount : selectComponentAmount) * 100}%`}}></div>
                                        <div className="sell-bar-text">{`${((count || 0) / (selectResource ? selectResourceAmount : selectComponentAmount) * 100).toFixed(0)}%`}</div>
                                    </div>

                                    <div className="sell-amount">
                                        <button className="sell-minus" onClick={() => inputChange('minus')}>–</button>
                                        <input
                                            id="input-amount"
                                            name="Input amount"
                                            className="sell-input"
                                            value={count}
                                            placeholder={placeholder || ''}
                                            onChange={(e) => handleChange(e.target.value)}
                                            onFocus={() => setPlaceholder('')}
                                            onBlur={() => setPlaceholder('0')}
                                        />
                                        <button className="sell-plus" onClick={() => inputChange('plus')}>+</button>
                                    </div>

                                    <div className="coins-box">
                                        <span>+{coinsAdd}</span>
                                        <img src={CoinImage} alt="$SEACOIN" />
                                    </div>

                                </div>
                            </div>

                            <button className={`coins-button ${loading || count == 0 || !count ? 'disabled' : ''}`}
                            disabled={loading || count == 0 || !count}
                            onClick={() => earnCoins()}
                            >
                                {!loading ? 
                                    t('exchange') :
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="75%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                }
                                
                            </button> 

                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default SelectModal;