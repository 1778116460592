import React, { useEffect, useState} from 'react';
import './mint-ships.scss';
import { useTranslate } from '../../translate/translate';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { toNano, beginCell, Address } from '@ton/core';
import { getMinted, changeWallet, getTimeServer } from '../../requests';
import Warning from '../../warnings';
import Alert from '../../alerts';

import BackImage from '../back.png';
import capacityImage from '../ships/cont-icon.png'
import tonImage from '../../market/ton.png'
import ggImage from './gg.png'
import config from '../ships/ships_upgrade.json';

import PioneerImage from '../ships/pioneer.png';
import ExplorerImage from '../ships/explorer.png';
import VoyagerImage from '../ships/voyager.png';
import OdysseyImage from '../ships/odyssey.png';
import TitanImage from '../ships/titan.png';

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const shipType = {
    'Pioneer': 0,
    'Explorer': 1,
    'Voyager': 2,
    'Odyssey': 3,
    'Titan': 4
};

const shipPrices = {
    'Pioneer': 5,
    'Explorer': 10,
    'Voyager': 15,
    'Odyssey': 20,
    'Titan': 25
};

const shipSupply = {
    'Pioneer': 100,
    'Explorer': 90,
    'Voyager': 75,
    'Odyssey': 60,
    'Titan': 50
};

const cashbackValue = {
    'Pioneer': 20,
    'Explorer': 30,
    'Voyager': 40,
    'Odyssey': 45,
    'Titan': 50
};

const ships = Object.keys(shipImages);

const MintShipsPage = ({ setMintPage, updateShips }) => {
    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const t = useTranslate();
    const [currentShipType, setCurrentShipType] = useState(ships[0]);
    const [selectNumber, setSelectNumber] = useState(0);
    const [isMinting, setIsMinting] = useState(false);
    const [shipMinted, setShipMinted] = useState(null);
    const [warning, setWarning] = useState(false);
    const [alert, setAlert] = useState(null);
    const [showPyro, setShowPyro] = useState(false);
    const [cashback, setCashback] = useState(false);


    //////////////////////////////////////// CASHBACK /////////////////////////////////
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        
        // Определяем начало и конец указанного диапазона
        const startDate = new Date('2024-11-11T00:00:00Z').getTime();
        const endDate = new Date('2024-11-11T23:59:59Z').getTime();

        // Проверяем, что время на сервере находится в пределах указанного диапазона
        if (serverTimeMs >= startDate && serverTimeMs <= endDate) {
            setCashback(true);
        }
    };

    useEffect(() => {
        fetchServerTime();
    }, []);
    ////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        const fetch = async () => {
            const mintedShipsRes = await getMinted(1); // 1 - корабли, 2 - контейнеры

            const mintedShipsObj = mintedShipsRes.reduce((acc, value, index) => {
                acc[Object.keys(shipType)[index]] = value;
                return acc;
            }, {});

            const updatedShipMinted = Object.keys(shipSupply).map(type => {
                return shipSupply[type] - (mintedShipsObj[type] || 0);
            });

            setShipMinted(updatedShipMinted);
        };
        fetch();
    }, []);

    const nextShip = () => {
        const currentIndex = ships.indexOf(currentShipType);
        const nextIndex = (currentIndex + 1) % ships.length;
        setSelectNumber(0); 
        setCurrentShipType(ships[nextIndex]);
    };

    const prevShip = () => {
        const currentIndex = ships.indexOf(currentShipType);
        const prevIndex = (currentIndex - 1 + ships.length) % ships.length;
        setSelectNumber(0); 
        setCurrentShipType(ships[prevIndex]);
    };

    const getShipCapacity = (shipType, level) => {
        const shipConfig = config.ships.find(ship => ship.type === shipType);
        if (shipConfig) {
            const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
            return levelConfig ? levelConfig.capacity : 'N/A';
        }
        return '';
    };

    const incrementNumber = () => {
        if (selectNumber < shipMinted[shipType[currentShipType]]) {
            const newNumber = parseInt(selectNumber) + 1; 
            setSelectNumber(newNumber); 
        }
    };
    
    const decrementNumber = () => {
        if (parseInt(selectNumber) > 0) { 
            const newNumber = parseInt(selectNumber) - 1; 
            setSelectNumber(newNumber); 
        }
    };

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
            if (disconnectInfo) {
                await tonConnectUI.disconnect();
                setIsConnected(false);
                window.wallet = undefined;
                setWarning(true);
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const mintShip = async (type, number) => {
        if (window.wallet) {
            setIsMinting(true);

            const amountSend = (shipPrices[currentShipType] + 0.2)*number;
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                  {
                    address: 'EQAjt0VJaaV2bmfO5QuhenHTly5P7-LvO5cR4w59P1h4ruhI',
                    amount: String(toNano(amountSend)),
                    payload: beginCell()
                      .storeUint(0x322, 32)
                      .storeUint(type, 8) // type
                      .storeAddress(Address.parse(window.wallet)) //owner address
                      .storeUint(number, 64) // amount
                      .endCell().toBoc().toString('base64'),
                  },
                ],
            };
        
            tonConnectUI.sendTransaction(transaction).then(() => {
                setIsMinting(false);
                setSelectNumber(0); 

                setAlert('minted-ship');
                setShowPyro(true);
                setTimeout(() => {
                    setShowPyro(false);
                }, 3000);

            }).catch(() => {
                setIsMinting(false);
                setAlert('minted-close');
            })
        } else { await tonConnectUI.openModal(); }
    };

    const mintBack = () => {
        updateShips();
        setMintPage(false);
    };

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

            {showPyro && (
                <div className="pyro">
                    <div className="before"></div>
                    <div className="after"></div>
                </div>
            )}

            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            <div className="mint-ships-overlay">
                <div className="mint-ships-header">
                    <button className="mint-ships-back" onClick={() => mintBack()}>
                        <img src={BackImage} alt="Back" />
                    </button>
                    <span className="mint-ships-title">
                        {t("ship-mint")}
                    </span>
                    <button className="collection-link-button" onClick={() => window.Telegram.WebApp.openLink('https://getgems.io/collection/EQAjt0VJaaV2bmfO5QuhenHTly5P7-LvO5cR4w59P1h4ruhI')}>
                        <img src={ggImage} alt="Getgems" />
                    </button>
                </div>

                <div className="mint-slider-box">
                    <div className="mint-slider">
                        <button className="mint-ship-item" onClick={prevShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={shipImages[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]} alt="Previous Ship" />
                            <div className="mint-ship-slider-name">{ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]}</div>
                            {shipMinted && <div className={`mint-ship-slider-supply ${shipMinted ? 'ready' : ''}`}>Supply: {shipMinted[shipType[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]]}/{shipSupply[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]}</div>}
                            <div className="mint-ship-capacity">
                                {getShipCapacity(ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length], 1)}
                                <img src={capacityImage} alt='capacity'/>
                            </div>
                            <div className="mint-ship-slider-price">
                                <span>{t("price")}: {shipPrices[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]}</span>
                                <img src={tonImage} alt="TON" />
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[ships[(ships.indexOf(currentShipType) - 1 + ships.length) % ships.length]]}%</span>
                                </div>
                            }  

                        </button>
                        <button className="mint-ship-item">
                            <img src={shipImages[currentShipType]} alt="Current Ship" />
                            <div className="mint-ship-slider-name">{currentShipType}</div>
                            {shipMinted && <div className={`mint-ship-slider-supply ${shipMinted ? 'ready' : ''}`}>Supply: {shipMinted[shipType[currentShipType]]}/{shipSupply[currentShipType]}</div>}
                            <div className="mint-ship-capacity">
                                {getShipCapacity(currentShipType, 1)}
                                <img src={capacityImage} alt='capacity'/>
                            </div>
                            <div className="mint-ship-slider-price">
                                <span>{t("price")}: {shipPrices[currentShipType]}</span>
                                <img src={tonImage} alt="TON" />
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[currentShipType]}%</span>
                                </div>
                            }  

                        </button>
                        <button className="mint-ship-item" onClick={nextShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                            <img src={shipImages[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]} alt="Next Ship" />
                            <div className="mint-ship-slider-name">{ships[(ships.indexOf(currentShipType) + 1) % ships.length]}</div>
                            {shipMinted && <div className={`mint-ship-slider-supply ${shipMinted ? 'ready' : ''}`}>Supply: {shipMinted[shipType[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]]}/{shipSupply[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]}</div>}
                            <div className="mint-ship-capacity">
                                {getShipCapacity(ships[(ships.indexOf(currentShipType) + 1) % ships.length], 1)}
                                <img src={capacityImage} alt='capacity'/>
                            </div>
                            <div className="mint-ship-slider-price">
                                <span>{t("price")}: {shipPrices[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]}</span>
                                <img src={tonImage} alt="TON" />
                            </div>

                            {cashback &&
                                <div className="cashback">
                                    <span>CASHBACK</span>
                                    <span className="percent">{cashbackValue[ships[(ships.indexOf(currentShipType) + 1) % ships.length]]}%</span>
                                </div>
                            }  

                        </button>
                    </div>
                </div>

                <div className="mint-ship-number-box">
                    <button onClick={decrementNumber}>
                        <span>–</span>
                    </button>
                    <div className="mint-ship-number-input">{selectNumber}</div>
                    <button onClick={incrementNumber}>+</button>
                </div>

                <button className={`mint-ship-button ${selectNumber == 0 || isMinting ? 'disabled' : ''}`} onClick={() => mintShip(shipType[currentShipType], selectNumber)}>
                    {isMinting ? (
                        <div className="loading-animation-minting">
                            <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    ) : (
                        selectNumber == 0 ? (
                            t("mint-nft")
                        ) : (
                            <>
                                {t("mint-nft")} (
                                {shipPrices[currentShipType] * selectNumber}
                                <img src={tonImage} alt="TON" />)
                            </>
                        )
                    )} 
                </button>
                <span className="detention-ships">{t("detention")}</span>
            </div>
        </> 
    );
};

export default MintShipsPage;