import React, { useState, useEffect } from 'react';
import "./loading.scss"

import { useTonAddress } from '@tonconnect/ui-react';
import { useTonConnectUI } from '@tonconnect/ui-react';

import { sendDataToServer } from './requests.jsx';

import Fon from './fon.png';
import Logo from './logo.png';
import City from './port/city.png';
import ArrowLeft from './market/arrow_left.png';
import ArrowRight from './market/arrow_right.png';
import Filter from './market/filter.png';
import Refresh from './market/refresh.png';
import Ton from './market/ton.png';
import ProfileNav from './navigation/profile.png';
import PortNav from './navigation/port.png';
import TripNav from './navigation/trip.png';
import MarketNav from './navigation/market.png';
import ClansNav from './navigation/clans.png';
import BackPort from './port/back.png';
import ContPort from './port/cont.png';
import ListPort from './port/list.png';
import Marker1Port from './port/marker1.png';
import Marker2Port from './port/marker2.png';
import Marker3Port from './port/marker3.png';
import Marker4Port from './port/marker4.png';
import RentPort from './port/rent.png';
import RentShipPort from './port/rent-ship.png';
import ShipPort from './port/ship.png';
import UpgradePort from './port/upgrade.png';
import UpgradeLvlPort from './port/upgrade_lvl.png';
import XpPort from './port/xp.png';
import BlueCont from './port/cont/blue.png';
import RedCont from './port/cont/red.png';
import GreenCont from './port/cont/green.png';
import BronzeCont from './port/cont/bronze.png';
import SilverCont from './port/cont/silver.png';
import GoldCont from './port/cont/gold.png';
import UpgradeCont from './port/cont/upgrade.png';
import UpgradeNoCont from './port/cont/upgrade_no.png';
import UpgradeYesCont from './port/cont/upgrade_yes.png';
import ContIconShips from './port/ships/cont-icon.png';
import ExplorerShips from './port/ships/explorer.png';
import OdysseyShips from './port/ships/odyssey.png';
import PioneerShips from './port/ships/pioneer.png';
import TitanShips from './port/ships/titan.png';
import VoyagerShips from './port/ships/voyager.png';
import ContProfile from './profile/cont.png';
import CrProfile from './profile/cr.png';
import FriendsProfile from './profile/friends.png';
import GgProfile from './profile/gg.png';
import InfoProfile from './profile/info.png';
import MgProfile from './profile/mg.png';
import MgInfo1Profile from './profile/mg-info1.png';
import ReferalProfile from './profile/referal.png';
import SuccessProfile from './profile/success.png';
import TelegramProfile from './profile/telegram.png';
import Trap1Profile from './profile/trap1.png';
import Trap2Profile from './profile/trap2.png';
import Trap3Profile from './profile/trap3.png';
import Resource1 from './resources/1.png';
import Resource2 from './resources/2.png';
import Resource3 from './resources/3.png';
import Resource4 from './resources/4.png';
import Resource5 from './resources/5.png';
import Resource6 from './resources/6.png';
import Resource7 from './resources/7.png';
import Resource8 from './resources/8.png';
import Resource9 from './resources/9.png';
import Resource10 from './resources/10.png';
import Resource11 from './resources/11.png';
import Resource12 from './resources/12.png';
import Resource13 from './resources/11.png';
import Resource14 from './resources/12.png';
import EnTranslate from './translate/en.png';
import RuTranslate from './translate/ru.png';
import AnchorVoyage from './voyage/anchor.png';
import HelmVoyage from './voyage/helm.png';
import LockVoyage from './voyage/lock.png';
import MapVoyage from './voyage/map3.jpg';
import MarkerCloseVoyage from './voyage/marker_close.png';
import MarkerOpenVoyage from './voyage/marker_open.png';
import TimeVoyage from './voyage/time.png';
import AfricaCountry from './voyage/country/Africa.jpeg';
import AmericaCountry from './voyage/country/America.jpeg';
import AntarcticaCountry from './voyage/country/Antarctica.jpeg';
import AustraliaCountry from './voyage/country/Australia.jpeg';
import BrazilCountry from './voyage/country/Brazil.jpeg';
import ChinaCountry from './voyage/country/China.jpeg';
import EuropeCountry from './voyage/country/Europe.jpeg';
import GreenlandCountry from './voyage/country/Greenland.jpeg';
import IndiaCountry from './voyage/country/India.jpeg';
import MadagascarCountry from './voyage/country/Madagascar.jpeg';
import RussiaCountry from './voyage/country/Russia.jpeg';
import SaudiCountry from './voyage/country/Saudi.jpeg';
import Wallet from './profile/wallet.png';
import manImage from './tutorial/man.png';
import Ava1 from './profile/ava/1.png';
import Ava2 from './profile/ava/2.png';
import Ava3 from './profile/ava/3.png';
import Ava4 from './profile/ava/4.png';
import Ava5 from './profile/ava/5.png';
import Ava6 from './profile/ava/6.png';
import ggShips from './port/ships/gg.png';
import ggConts from './port/cont/gg.png';
import contPlus from './port/cont_plus.png';
import rating from './profile/rating.png';
import rating2 from './profile/rating_2.png';
import backWhite from './port/back_white.png';
import ErrorIcon from './error.png';
import infoIcon from './info.png'
import LeftArrowImage from './port/arrow-left.png';
import RightArrowImage from './port/arrow-right.png';
import coin from './navigation/airdrop.png';
import airdropArrow from './airdrop/arrow.png';

//////////////// TEMP /////////////////////
import Temp1 from './voyage/temp/budget.png';
import Temp2 from './voyage/temp/rank.png';
import Temp3 from './voyage/temp/hmstr_anounce.png';
import Temp4 from './voyage/temp/hmstr_coin.png';
import Temp5 from './voyage/temp/hmstr_icon.png';
///////////////// Technologies /////////////////////
import empty from './technologies/empty.png';
////////////////////////////////////////////////////
import port1 from './port/research.png';
import port2 from './port/store.png';
import port3 from './port/workshop.png';
import research from './port/research/create.jpg';
import rigs1 from './port/rigs/capacity.png';
import rigs2 from './port/rigs/speed.png';
import rigs3 from './port/rigs/rig.jpeg';
import rigs4 from './port/rigs/x2.png';
import ships1 from './port/ships/speed.png';
import workshop1 from './port/workshop/workshop.jpeg';
import workshop2 from './port/workshop/workshop_lines2.png';
import workshop3 from './port/workshop/workshop_lines3.png';
import workshop4 from './port/workshop/workshop_lines4.png';
import nav1 from './navigation/arrow-down.png';
/////////////////// Components ////////////////////////
import c1 from './components/1.png';
import c2 from './components/2.png';
import c3 from './components/3.png';
import c4 from './components/4.png';
import c5 from './components/5.png';
import c6 from './components/6.png';
import c7 from './components/7.png';
import c8 from './components/8.png';
import c9 from './components/9.png';
import c10 from './components/10.png';
import c11 from './components/11.png';
import c12 from './components/12.png';
import c13 from './components/13.png';
import c14 from './components/14.png';
import c15 from './components/15.png';
import c16 from './components/16.png';
import c17 from './components/17.png';
import c18 from './components/18.png';
import c19 from './components/19.png';
import c20 from './components/20.png';
import c21 from './components/21.png';
/////////////////////////////// TUTORIAL ////////////////////
import rigImage from './tutorial/oil-rig.png';
import voyageImage from './navigation/trip.png';
import componentImage from './components/3.png';
import marketImage from './navigation/market.png';
import finalImage from './tutorial/final.png';
import voyagesImage from './tutorial/voyages.jpg';
import warehouseImage from './tutorial/warehouse.jpg';
import warehouseEnImage from './tutorial/warehouse_en.jpg';
import rigsImage from './tutorial/rigs.jpg';
import rigsEnImage from './tutorial/rigs_en.jpg';
import componentsImage from './tutorial/components.jpg';
import componentsEnImage from './tutorial/components.jpg';
import marketsImage from './tutorial/market.jpg';
import marketsEnImage from './tutorial/market_en.jpg';
import logoImage from './logo.png';
///////////////////////////// HODL /////////////////////////
import hodl1 from './voyage/temp/hodl_icon.png';
import hodl2 from './voyage/temp/hodl_image.jpg';
import hodl3 from './voyage/temp/hodl_quest.jpg';

const imagesToPreload = [
    Fon, Logo, City,
    ArrowLeft, ArrowRight, Filter, Refresh, Ton,
    ProfileNav, PortNav, TripNav, MarketNav, ClansNav,
    BackPort, ContPort, ListPort, Marker1Port, Marker2Port, Marker3Port, Marker4Port,
    RentPort, RentShipPort, ShipPort, UpgradePort, UpgradeLvlPort, XpPort,
    BlueCont, RedCont, GreenCont, BronzeCont, SilverCont, GoldCont,
    UpgradeCont, UpgradeNoCont, UpgradeYesCont,
    ContIconShips, ExplorerShips, OdysseyShips, PioneerShips, TitanShips, VoyagerShips,
    ContProfile, CrProfile, FriendsProfile, GgProfile, InfoProfile, MgProfile, MgInfo1Profile,
    ReferalProfile, SuccessProfile, TelegramProfile, Trap1Profile, Trap2Profile, Trap3Profile,
    Resource1, Resource2, Resource3, Resource4, Resource5, Resource6, Resource7, Resource8,
    Resource9, Resource10, Resource11, Resource12, Resource13, Resource14,
    EnTranslate, RuTranslate, ggShips, ggConts,
    AnchorVoyage, HelmVoyage, LockVoyage, MapVoyage, MarkerCloseVoyage, MarkerOpenVoyage, TimeVoyage,
    AfricaCountry, AmericaCountry, AntarcticaCountry, AustraliaCountry, BrazilCountry, ChinaCountry,
    EuropeCountry, GreenlandCountry, IndiaCountry, MadagascarCountry, RussiaCountry, SaudiCountry,
    Wallet, manImage, Ava1, Ava2, Ava3, Ava4, Ava5, Ava6,
    contPlus, rating, rating2, backWhite, ErrorIcon,
    Temp1, Temp2, Temp3, Temp4, Temp5, // TEMP
    infoIcon, LeftArrowImage, RightArrowImage, empty,
    port1, port2, port3, research, rigs1, rigs2, rigs3, rigs4, ships1, workshop1, workshop2,
    workshop3, workshop4, nav1, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14,
    c15, c16, c17, c18, c19, c20, c21,
    rigImage, voyageImage, componentImage, marketImage, finalImage, voyagesImage, warehouseImage,
    warehouseEnImage, rigsImage, rigsEnImage, componentsImage, componentsEnImage, marketsImage,
    marketsEnImage, logoImage, hodl1, hodl2, hodl3, coin, airdropArrow
];

const Loading = ({ loading, setLoading, setTutorial }) => {
    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [preloading, setPreloading] = useState(true);
 
    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            window.wallet = wallet;
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);
    
    useEffect(() => { // LOADING
        if (!preloading) {
            const fetchData = async () => {
                const minimumLoadingTime = 1500; // Минимальное время загрузки
                const startTime = Date.now();
        
                if (window.tgID) {
                    try {
                        // Проверка на наличие window.wallet в течение 1.5 секунд
                        const walletPromise = new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(window.wallet);
                            }, minimumLoadingTime);
                        });
        
                        // Загрузка картинок
                        const preloadImages = async () => {
                            const totalImages = imagesToPreload.length;
                            let loadedImages = 0;
    
                            const promises = imagesToPreload.map((url, index) => {
                                return new Promise((resolve, reject) => {
                                    const img = new Image();
                                    img.src = url;
                                    img.onload = () => {
                                        loadedImages++;
                                        setLoadingProgress(Math.round((loadedImages / totalImages) * 100));
                                        resolve();
                                    };
                                    img.onerror = reject;
                                });
                            });
    
                            try {
                                await Promise.all(promises);
                            } catch (error) {
                                console.error('Error loading images: ', error);
                            }
                        };
        
                        // Запускаем загрузку картинок и ожидание wallet параллельно
                        const [wallet] = await Promise.all([walletPromise, preloadImages()]);
        
                        const referer = window.referer || 0; // Если реферера нет - то 0
                        const lastname = window.lastname || 0; // Если lastname нет - то 0
                        // wallet отправляется либо есть либо undefined
                        const disconnectInfo = await sendDataToServer(wallet, window.tgID, referer, window.firstname, lastname);
    
                        // Отключить кошелек, если это другой акк
                        if (disconnectInfo && window.wallet) {
                            await tonConnectUI.disconnect();
                            setIsConnected(false);
                            window.wallet = undefined;
                        }
        
                        // Создаем обещание для минимальной задержки
                        const delayPromise = new Promise((resolve) => {
                            setTimeout(resolve, minimumLoadingTime - (Date.now() - startTime));
                        });
        
                        // Ожидаем выполнение обещания задержки
                        await delayPromise;
        
                        // Убедимся, что setLoading(false) вызывается после загрузки всех изображений и минимальной задержки
                        if (window.tutorial == false) {
                            setTutorial(true); // Вызвать обучение, если оно не пройдено
                        }
                        setLoading(false);
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        setLoading(false);
                    }
                }
            };

            fetchData();
        } else {
            // Подгрузить фон и лого
            const preloadImages = async () => {
                const promises = imagesToPreload.slice(0, 2).map(url => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = url;
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                });
    
                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.error('Error loading images: ', error);
                }
            };
    
            const timerPromise = new Promise((resolve) => {
                setTimeout(resolve, 400);
            });
    
            Promise.all([preloadImages(), timerPromise]).then(() => {
                setPreloading(false);
            });
        }
        
    }, [window.tgID, preloading]);

    return (
        <>
            {preloading ? (
                <div className='preloading'>Container Rush</div>
            ) : (
                <div className='load'>
                    <img className='logo' src={Logo} alt="Container Rush" />
                    <div className="loading-progress-bar-v2">
                        <div className="loading-progress-bar-fill" style={{ width: `${loadingProgress}%` }}></div>
                        <span className="loading-progress">{loadingProgress}%</span>
                    </div>
                    <span className='version'>v1.5.2</span>
                </div>
            
            )}
        </>
    );
};

export default Loading;