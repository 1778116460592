import React, { useState, useEffect } from 'react';
import "./sell-modal.scss";
import ReactDOM from 'react-dom';
import { foundLots, changeWallet, marketAdd } from '../requests';
import { useTranslate } from '../translate/translate';
import { formatMarket } from '../format-number';
import TonImage from './ton.png';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTonAddress } from '@tonconnect/ui-react';
import Alert from '../alerts';

const ModalSell = ({ sellModal, setSellModal, resourceImages, componentImages, resourceMinimum, setAlert, componentsMinimum }) => {

    const t = useTranslate();
    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonAddress(false);
    const [isConnected, setIsConnected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentPriceLot, setCurrentPriceLot] = useState(0);
    const [yourPrice, setYourPrice] = useState(0);
    const [placeholder, setPlaceholder] = useState();
    const [alertModal, setAlertModal] = useState();

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            await changeWallet(window.tgID, wallet); // Присваивается window.wallet
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    useEffect(() => {
        const fetchCurrentPriceLot = async () => {
            const responsefoundLots = await foundLots(sellModal.type, sellModal.number+1);
            if (responsefoundLots.length > 0) {
                const lot = responsefoundLots.reduce((prev, curr) => (prev.price < curr.price) ? prev : curr);
                setCurrentPriceLot(lot);
            } else {
                setCurrentPriceLot(0);
            }
        };
        fetchCurrentPriceLot();
    }, []);

    const handlePriceChange = (value) => {
        value = value.replace(/,/g, '.');
        let cleanedValue = value.replace(/[^0-9.]/g, ''); // Удаляем все символы, кроме цифр и точки
        const parts = cleanedValue.split('.');

        // Проверяем целую часть
        if (parts[0].length > 1 && parts[0][0] === '0') {
            parts[0] = parts[0].slice(1); // Убираем ведущий ноль, если целая часть больше одной цифры
        }
        // Проверяем дробную часть
        if (parts[1] && parts[1].length > 5) {
            parts[1] = parts[1].slice(0, 5); // Ограничиваем дробную часть пятью цифрами
        }
        // Проверяем, что точка не является первым символом
        if (cleanedValue[0] === '.') {
            cleanedValue = '0' + cleanedValue; // Добавляем ноль перед точкой
        }
        // Проверяем, что точка не повторяется
        if (cleanedValue.indexOf('.') !== cleanedValue.lastIndexOf('.')) {
            cleanedValue = cleanedValue.slice(0, cleanedValue.lastIndexOf('.')); // Убираем все точки, кроме последней
        }

        cleanedValue = parts.join('.');

        if (cleanedValue === '0.00000') {
            cleanedValue = '0.00001';
        }

        setYourPrice(cleanedValue);
    };

    const inputChange = (action) => {
        if (sellModal.type === 'resources') {
            if (action === 'plus') {
                if (count + resourceMinimum[sellModal.number+1] <= sellModal.count) {
                    setCount(prevCount => prevCount + resourceMinimum[sellModal.number+1]);
                }
            } else if (action === 'minus') {
                if (count - resourceMinimum[sellModal.number+1] < 0) {
                    setCount(0);
                } else {
                    setCount(prevCount => prevCount - resourceMinimum[sellModal.number+1]);
                }
            }
        } else if (sellModal.type === 'components') {
            if (action === 'plus') {
                if (count + componentsMinimum <= sellModal.count) {
                    setCount(prevCount => prevCount + componentsMinimum);
                }
            } else if (action === 'minus') {
                if (count - componentsMinimum < 0) {
                    setCount(0);
                } else {
                    setCount(prevCount => prevCount - componentsMinimum);
                }
            }
        }
    };

    const formatPrice = (price) => {
        if (isNaN(price) || price === undefined || !isFinite(price)) {
            return '0';
        }
        return parseFloat(price).toString();
    };

    const sellClick = (y) => {
        setLoading(true);
        if (window.lvl >= 30) {
            if (window.wallet) {
                let elements;
                let minimum;
                if (sellModal.type === 'resources') {
                    elements = Array(14).fill(0);
                    minimum = resourceMinimum[sellModal.number + 1];
                } else if (sellModal.type === 'components') {
                    elements = Array(21).fill(0);
                    minimum = componentsMinimum;
                }
                elements[sellModal.number] = count;
    
                marketAdd(window.tgID, window.wallet, sellModal.type, yourPrice, sellModal.number + 1, count, elements, minimum)
                    .then(() => {
                        setAlert('market-sell');
                        setSellModal(null);
                    })
                    .catch(error => {
                        console.error('Error adding to market:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                if (!isConnected) {
                    tonConnectUI.openModal()
                        .then(() => {
                        })
                        .catch(error => {
                            console.error('Error opening modal:', error);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                } else {
                    setLoading(false);
                }
            }
        } else {
            setAlertModal('market-min-lvl');
            setLoading(false);
        }
    };

    return (
        <>     
            {alertModal ? <Alert alert={alertModal} setAlert={setAlertModal} /> : null}  
            {sellModal ? (
                ReactDOM.createPortal(
                    <div className="sell-modal-overlay" onClick={() => setSellModal(null)}>
                        <div className="box" onClick={(e) => e.stopPropagation()}>
                            <div className="title">{t('market-sell')}</div>

                            <div className="lot">
                                <div className="lot-left">
                                    <div className="img-box"> 
                                        {sellModal.type === 'resources' ? 
                                            (<img src={resourceImages[sellModal.number+1]} alt={`Resource ${sellModal.number+1}`} />) :
                                            (<img src={componentImages[sellModal.number+1]} alt={`Component ${sellModal.number+1}`} />)
                                        }
                                        <span>{formatMarket(sellModal.count)}</span>
                                    </div>

                                    <div className="minimum">
                                        <span>{t('minimum')}</span>
                                        <span>{sellModal.type === 'resources' ? formatMarket(resourceMinimum[sellModal.number+1]) : componentsMinimum}</span>
                                    </div>
                                </div>

                                <div className="lot-right">
                                    <div className="sell-bar">
                                        <div className="sell-bar-fill" style={{ width: `${(count || 0) / sellModal.count * 100}%`}}></div>
                                        <div className="sell-bar-text">{`${((count || 0) / sellModal.count * 100).toFixed(0)}%`}</div>
                                    </div>

                                    <div className="sell-amount">
                                        <button className="sell-minus" onClick={() => inputChange('minus')}>–</button>
                                        <div className="sell-input"> {count > 0 ? formatMarket(count) : 0}</div>
                                        <button className="sell-plus" onClick={() => inputChange('plus')}>+</button>
                                    </div>

                                    <div className="sell-info-boxes">
                                        <div className="current-price-sell-box">
                                            <span className="title-price">{t('current-price')}</span>
                                            <div className="current-price">
                                                {sellModal.type === 'resources' ? formatMarket(resourceMinimum[sellModal.number+1]) : componentsMinimum}
                                                {sellModal.type === 'resources' ? 
                                                    (<img src={resourceImages[sellModal.number+1]} alt={`Resource ${sellModal.number+1}`} />) :
                                                    (<img src={componentImages[sellModal.number+1]} alt={`Component ${sellModal.number+1}`} />)
                                                }
                                            </div>

                                            <div className="current-price-sell">
                                                {currentPriceLot ? (
                                                    <>
                                                    {currentPriceLot.price.toFixed(5)}
                                                    <img src={TonImage} alt="Ton" />
                                                    </> 
                                                ) : t("no")}
                                            </div>

                                        </div>
                                        <div className="your-price-sell-box">
                                            <span className="title-price">{t('your-price')}</span>
                                            <div className="your-price">
                                                {sellModal.type === 'resources' ? formatMarket(resourceMinimum[sellModal.number+1]) : componentsMinimum}
                                                {sellModal.type === 'resources' ? 
                                                    (<img src={resourceImages[sellModal.number+1]} alt={`Resource ${sellModal.number+1}`} />) :
                                                    (<img src={componentImages[sellModal.number+1]} alt={`Component ${sellModal.number+1}`} />)
                                                }
                                            </div>

                                            <div className="your-price-sell">
                                                {sellModal.type === 'resources' ? 
                                                    formatPrice((yourPrice * resourceMinimum[sellModal.number+1] / count).toFixed(5)) :
                                                    formatPrice((yourPrice * componentsMinimum / count).toFixed(5))
                                                }
                                                <img src={TonImage} alt="Ton" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sell-box-v2">
                                        <img className="price-img-input" src={TonImage} />
                                        <input
                                            id="input-price-v2"
                                            name="Input price"
                                            className="input-price-v2"
                                            value={yourPrice || ''}
                                            placeholder={placeholder || t('input-price')}
                                            onChange={(e) => handlePriceChange(e.target.value)}
                                            onFocus={() => setPlaceholder('')}
                                            onBlur={() => setPlaceholder(t('input-price'))}
                                        />
                                        <button className={`button-sell-v2 ${count > 0 && yourPrice > 0 && !isNaN(yourPrice) && yourPrice !== undefined && yourPrice !== Infinity && !loading ? '' : 'disabled'}`}
                                            onClick={() => sellClick()}
                                            disabled={count == 0 && yourPrice ==0 && isNaN(yourPrice) && yourPrice == undefined && yourPrice == Infinity && loading}
                                        >
                                            {!loading ? 
                                                t('sell') :
                                                <div className="loading-animation-selling">
                                                    <svg className="spinner" strokeWidth="65px" height="75%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                    </svg>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>,
                    document.body
                ) 
            ) : 
                null
            }
        </>
    );
};

export default ModalSell;