
import React, { useState, useEffect } from 'react';
import './header.scss';
import { useTranslate } from '../translate/translate';
import { getUserInfo } from '../requests'
import HeaderStore from './header-store';
import SoonAlert from '../technologies/soon';

import Ava1 from '../profile/ava/1.png';
import Ava2 from '../profile/ava/2.png';
import Ava3 from '../profile/ava/3.png';
import Ava4 from '../profile/ava/4.png';
import Ava5 from '../profile/ava/5.png';
import Ava6 from '../profile/ava/6.png';
import ArrowDownImage from './arrow-down.png';
import StoreIcon from '../port/store.png';
import EmptyIcon from '../technologies/empty.png';

const Header = ({ handleChangeSection, LVL, XP, setFlotilias }) => {
    const t = useTranslate();
    const [avatar, setAvatar] = useState(window.avatar);
    const [headerStore, setHeaderStore] = useState(false);
    const [storeType, setStoreType] = useState('Resources');
    const [currentXp, setCurrentXp] = useState(XP);
    const [xpPercentage, setXpPercentage] = useState(0);

    const [currentXpDisplay, setCurrentXpDisplay] = useState(0);
    const [xpDisplay, setXpDisplay] = useState(0);
    const [soonAlert, setSoonAlert] = useState(null);

    const avatarImage = avatar === 1 ? Ava1 :
                    avatar === 2 ? Ava2 :
                    avatar === 3 ? Ava3 :
                    avatar === 4 ? Ava4 :
                    avatar === 5 ? Ava5 :
                    avatar === 6 ? Ava6 : Ava1;

    const fetchData = async () => {
        await getUserInfo(window.tgID);

        let currentXpNeeded = LVL*10;
        let previousXpNeeded = XP - (((LVL - 1) * LVL * 10) / 2);

        const percentage = (previousXpNeeded / currentXpNeeded) * 100;

        setCurrentXp(currentXp);
        setXpPercentage(percentage);
        setXpDisplay(currentXpNeeded);
        setCurrentXpDisplay(previousXpNeeded);
    }

    useEffect(() => {
        fetchData();
    }, [XP]);

    const soonClick = (event) => {
        const { clientX, clientY } = event;
        setSoonAlert({ page: 'header', x: clientX, y: clientY });
    };

    const clickHeaderStore = () => {
        setStoreType('Resources');
        setHeaderStore((prevHeaderStore) => !prevHeaderStore);
    };

    const goFlotilias = () => {
        handleChangeSection('profile');
        //setFlotilias(true);
    };

    return (
        <>
        {soonAlert && <SoonAlert soonAlert={soonAlert} setSoonAlert={setSoonAlert}/>}
        
        <div className='header'>
            <button className='header-profile'>
                <div className='header-avatar'>
                    <img src={avatarImage} alt="Avatar" />
                    <div className='header-lvl'>
                        <span>{LVL}</span>
                    </div>
                </div>
                <div className="header-lvl-bar">
                    <div className="header-lvl-bar-fill" style={{ width: `${xpPercentage}%` }} ></div>
                    <div className="header-lvl-bar-text" >{currentXpDisplay}/{xpDisplay}</div>
                </div>
            </button>
            <div className='header-name-box'>
                <button className='header-clan' onClick={() => goFlotilias()}><u>{t('without-clan')}</u></button>
                <span className='header-firstname'>{window.firstname}</span>
                <span className='header-lastname'>{window.lastname}</span>
            </div>

            <div className='header-technologies'> 
                {Array.from({ length: 4 }).map((_, index) => (
                    <button key={index} className='technology' onClick={soonClick}>
                        <img src={EmptyIcon} alt="Technology" />
                    </button>
                ))}
            </div>

            <button className='header-store-button' onClick={clickHeaderStore}>
                <div className='header-store-button1'>
                    <img src={StoreIcon} alt="" />
                </div>
                
                <div className='header-store-button2'>
                    <img src={ArrowDownImage} alt="My store" />
                </div>
            </button>
        </div>
        <HeaderStore headerStore={headerStore} setHeaderStore={setHeaderStore} storeType={storeType} setStoreType={setStoreType}/>
        </>
        
    );
};

export default Header;

