
import React, { useEffect, useState, useRef } from 'react';
import './rigs.scss';
import { useTranslate } from '../../translate/translate';
import { getUserInfo, buildRig, startRig, claimRig, getTimeServer } from '../../requests'
import { format, formatCapacity } from '../../format-number';
import config_store from '../store/config_store.json';

import RigImage from './rig.jpeg';
import emptyTechIcon from '../../technologies/empty.png'
import rigs_config from './rigs.json'
import SpeedImage from './speed.png';
import CapacityImage from './capacity.png';
import UpgradeImage from '../cont/upgrade_yes.png';

import UpgradeRig from './upgrade';
import ClaimModal from './claim-modal';
import InfoRig from './info';
import SoonAlert from '../../technologies/soon';

import Resource1Image from '../../resources/1.png';
import Resource2Image from '../../resources/2.png';
import Component1Image from '../../components/1.png';
import Component2Image from '../../components/2.png';
import Component3Image from '../../components/3.png';
import Component4Image from '../../components/4.png';
import Component5Image from '../../components/5.png';
import Component6Image from '../../components/6.png';
import Component7Image from '../../components/7.png';
import Component8Image from '../../components/8.png';
import Component9Image from '../../components/9.png';
import Component10Image from '../../components/10.png';
import Component11Image from '../../components/11.png';
import Component12Image from '../../components/12.png';
import Component13Image from '../../components/13.png';
import Component14Image from '../../components/14.png';
import Component15Image from '../../components/15.png';
import Component16Image from '../../components/16.png';
import Component17Image from '../../components/17.png';
import Component18Image from '../../components/18.png';
import Component19Image from '../../components/19.png';
import Component20Image from '../../components/20.png';
import Component21Image from '../../components/21.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image
};

const componentImages = {
    1: Component1Image,
    2: Component2Image,
    3: Component3Image,
    4: Component4Image,
    5: Component5Image,
    6: Component6Image,
    7: Component7Image,
    8: Component8Image,
    9: Component9Image,
    10: Component10Image,
    11: Component11Image,
    12: Component12Image,
    13: Component13Image,
    14: Component14Image,
    15: Component15Image,
    16: Component16Image,
    17: Component17Image,
    18: Component18Image,
    19: Component19Image,
    20: Component20Image,
    21: Component21Image
};

const Rigs = ({ alert, setAlert }) => {
    const t = useTranslate();
    const [serverTime, setServerTime] = useState(null);
    const serverTimeRef = useRef(null);

    const [rigs, setRigs] = useState({});
    const [loadingRigs, setLoadingRigs] = useState(false);
    const [modalUpgrade, setModalUpgrade] = useState(null);
    const [loadBuild, setLoadBuild] = useState({});
    const [loadStart, setLoadStart] = useState({});
    const [showPyro, setShowPyro] = useState({});
    const [soonAlert, setSoonAlert] = useState(null);
    const [currentFill, setCurrentFill] = useState({});
    const [loadClaim, setLoadClaim] = useState({});
    const [loadCommonClaim, setLoadCommonClaim] = useState(false);
    const [modalClaim, setModalClaim] = useState(null);
    const [modalInfoRig, setModalInfoRig] = useState(null);

    const userResources = window.resources.map((value, index) => value - window.resources_market[index]);
    const userComponents = window.components.map((value, index) => value - window.components_market[index]);

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs; // Сохраняем в ref для обновлений каждую секунду
    };

    useEffect(() => {
        let tickIntervalId;

        fetchServerTime();

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(tickIntervalId);
        };
    }, []);

    const fetchData = async () => {
        await getUserInfo(window.tgID);
        setRigs(window.rigs);
        setLoadingRigs(true);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const getParams = (rig, param) => {
        let display;
        if (param === 'capacity') {
            const currentLevel = rigs_config.rigs[rig].capacity.find(level => level.level === rigs[rig].lvl_capacity);
            display = currentLevel.capacity;
        } else if (param === 'speed') {
            const currentLevel = rigs_config.rigs[rig].speed.find(level => level.level === rigs[rig].lvl_speed);
            display = currentLevel.speed;
        }
        return display;
    }

    useEffect(() => {
        setCurrentFill(prev => {
            const newFill = {};
            Object.keys(rigs).forEach(key => {
                if (rigs[key].active && rigs[key].timer) {
                    const timerDate = new Date(rigs[key].timer.replace(' ', 'T') + 'Z');
                    const elapsedTime = (serverTime - timerDate.getTime()) / 1000; // in seconds
                    const speed = getParams(key, 'speed');

                    // Если есть fill_timer, используем его для расчета
                    if (rigs[key].fill_timer) {
                        const timerDateFill = new Date(rigs[key].fill_timer.replace(' ', 'T') + 'Z');
                        const elapsedTimeFill = (timerDateFill.getTime() - timerDate.getTime()) / 1000;
                        const fill = (((elapsedTime / 3600) - (elapsedTimeFill / 3600)) * speed) + rigs[key].fill;
                        newFill[key] = fill;
                    } else {
                        // Иначе используем общий таймер
                        const fill = (elapsedTime / 3600) * speed;
                        newFill[key] = fill;
                    }
                }
            });
            return newFill;
        });
    
    }, [rigs, serverTime]);

    const buildRigFunc = (rig) => {
        setLoadBuild(prev => ({ ...prev, [rig]: true }));
        const buildRequirements = rigs_config.rigs[rig].build[0].requirements;
    
        const moneyCost = Array(14).fill(0);
    
        const resourceItem = buildRequirements.find(item => item.resourceId !== undefined);
        if (resourceItem) {
            moneyCost[0] = resourceItem.amount;
        }
    
        const componentsCost = Array(21).fill(0);
        buildRequirements.forEach(item => {
            if (item.componentId !== undefined) {
                componentsCost[item.componentId - 1] = item.amount;
            }
        });
    
        const isMoney = moneyCost.every((amount, index) => amount <= 0 || amount <= userResources[index]);
        const isComponents = componentsCost.every((amount, index) => amount <= 0 || amount <= userComponents[index]);
    
        if (isMoney) {
            if (isComponents) {
                buildRig(window.tgID, rig, moneyCost, componentsCost)
                    .then(() => {
                        fetchData();
                        setAlert('rig-builded');
                        setShowPyro(prev => ({ ...prev, [rig]: true }));
                        setTimeout(() => setShowPyro(prev => ({ ...prev, [rig]: false })), 1000);
                    })
                    .catch(error => {
                        setAlert('error');
                    })
                    .finally(() => {
                        setLoadBuild(prev => ({ ...prev, [rig]: false }));
                    });
            } else {
                setAlert('not-components-upgrade');
                setLoadBuild(prev => ({ ...prev, [rig]: false }));
            }
        } else {
            setAlert('not-money-upgrade');
            setLoadBuild(prev => ({ ...prev, [rig]: false }));
        }
    };

    const soonClick = (event) => {
        const { clientX, clientY } = event;
        setSoonAlert({ page: 'rig-soon ', x: clientX, y: clientY });
    };

    const startRigFunc = (rig) => {
        setLoadStart(prev => ({ ...prev, [rig]: true }));
        startRig(window.tgID, rig)
            .then(() => {
                fetchData();
                setAlert('rig-start');
            })
            .catch(error => {
                setAlert('error');
            })
            .finally(() => {
                setLoadStart(prev => ({ ...prev, [rig]: false }));
            });
    };

    const claim = (rig) => {
        setLoadCommonClaim(true);
        setLoadClaim(prev => ({ ...prev, [rig]: true }));
        const capacityFuel = config_store.store.find(store => store.lvl === window.lvl_store).capacity[0].resources[1];
        let fuel;
        if (currentFill[rig] > getParams(rig, 'capacity')) {
            fuel = getParams(rig, 'capacity');
        } else {
            fuel = currentFill[rig];
        }
    
        if (window.resources[1] + fuel > capacityFuel) {
            setAlert('component-full');
            setLoadClaim(prev => ({ ...prev, [rig]: false }));
            setLoadCommonClaim(false);
        } else {
            claimRig(window.tgID, rig, fuel)
                .then(() => {
                    setLoadingRigs(false);
                    fetchData();
                    setCurrentFill(prevState => ({ ...prevState, [rig]: 0 }));
                    setAlert('rig-claim');
                    setModalClaim(false);
                    setLoadCommonClaim(false);
                })
                .catch(error => {
                    setAlert('error');
                })
                .finally(() => {
                    setLoadClaim(prev => ({ ...prev, [rig]: false }));
                });
        }
    };

    const claimTon = () => {
        setLoadingRigs(false);
        fetchData();
        setAlert('rig-claim');
        setModalClaim(false);
    }

    return (
        <>
            {soonAlert && <SoonAlert soonAlert={soonAlert} setSoonAlert={setSoonAlert}/>}


            {modalInfoRig && <InfoRig modalInfoRig={modalInfoRig} setModalInfoRig={setModalInfoRig} rigs={rigs} rigs_config={rigs_config} />}

            {modalClaim && <ClaimModal modalClaim={modalClaim} setModalClaim={setModalClaim} claim={claim} 
            loadClaim={loadClaim} setLoadClaim={setLoadClaim} currentFill={currentFill} getParams={getParams} claimTon={claimTon}
            config_store={config_store} setAlert={setAlert} loadCommonClaim={loadCommonClaim} />}

            {modalUpgrade && <UpgradeRig modalUpgrade={modalUpgrade} setModalUpgrade={setModalUpgrade} resourceImages={resourceImages} componentImages={componentImages}
                setAlert={setAlert} setShowPyro={setShowPyro} userResources={userResources} userComponents={userComponents} rigs_config={rigs_config}
                CapacityImage={CapacityImage} SpeedImage={SpeedImage} rigs={rigs} fetchData={fetchData} currentFill={currentFill} />}

            {(rigs && loadingRigs && (!rigs[1].active || currentFill[1])) ? (Object.keys(rigs).map(key => {
                if (key === '1' || (rigs[1] && rigs[1].builded && key === '2') || (rigs[2] && rigs[2].builded && key === '3')) {
                    const buildRequirements = rigs_config.rigs[key].build[0].requirements;
                    return (
                        <div key={key} className="rig">
                            <img src={RigImage} alt={`Oil rig ${key}`} />
                            {rigs[key].builded ? 

                                <div className="rig-builded">
                                    <div className="name">{t('oil-rig')} {key}</div>
                                    <button className="info-active" onClick={() => setModalInfoRig(key)}>i</button>

                                    <div className="techs" onClick={soonClick}>
                                        <button className="tech">
                                            <img src={emptyTechIcon} alt="Technology" />
                                        </button>

                                        <button className="tech" onClick={soonClick}>
                                            <img src={emptyTechIcon} alt="Technology" />
                                        </button>

                                    </div>

                                    <div className="upgrades">
                                        <div className="upgrade">
                                            <div className="info-up">
                                                <img src={CapacityImage} alt="Capacity" />
                                                <span>{t("lvl")} {rigs[key].lvl_capacity}</span>
                                            </div>
                                            
                                            {rigs[key].lvl_capacity != 10 &&
                                                <button className="upgrade-button" onClick={() => setModalUpgrade({param: 'capacity', rig: key})}>
                                                    <img src={UpgradeImage} alt="Upgrade" />
                                                </button>
                                            }

                                        </div>

                                        <div className="upgrade">
                                            <div className="info-up">
                                                <img src={SpeedImage} alt="Capacity" />
                                                <span>{t("lvl")} {rigs[key].lvl_speed}</span>
                                            </div>

                                            {rigs[key].lvl_speed != 10 &&
                                                <button className="upgrade-button" onClick={() => setModalUpgrade({param: 'speed', rig: key})}>
                                                    <img src={UpgradeImage} alt="Upgrade" />
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <div className="farm-box">
                                        {!rigs[key].active ? 
                                            <button className={`start-button ${loadStart[key] ? 'disabled' : ''}`} disabled={loadStart[key]} onClick={() => startRigFunc(key)}>
                                                {!loadStart[key] ? 
                                                    <span>{t('start-mining')}</span> :
                                                    <div className="loading-start">
                                                        <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                        </svg>
                                                    </div>
                                                }
                                            </button>
                                            : 
                                            <div className="farm-box-active">
                                                <div className="farm-header">
                                                    <span className="part1">{t('mining')}</span>
                                                    <div className="part2">
                                                        <span>{getParams(key, 'speed')}</span>
                                                        <img src={Resource2Image} alt="Fuel" />
                                                        <span>/{t('h')}</span>
                                                    </div>
                                                </div>

                                                <div className="farm-farming">
                                                    <img src={CapacityImage} alt="Capacity" />
                                                    <div className="progress-bar">
                                                        <div className="progress-bar-fill" style={{ 
                                                            width: 100 * currentFill[key] / getParams(key, 'capacity') >= 100 
                                                            ? '100%' 
                                                            : `${100 * currentFill[key] / getParams(key, 'capacity')}%`
                                                        }}></div>
                                                        <div className="progress-bar-text">
                                                            {currentFill[key] 
                                                                ? currentFill[key].toFixed(2) <= getParams(key, 'capacity') 
                                                                    ? `${currentFill[key].toFixed(2)}/${getParams(key, 'capacity')}` 
                                                                    : `${getParams(key, 'capacity')}/${getParams(key, 'capacity')}` 
                                                                : '0.00/0'
                                                            }
                                                        </div>
                                                        <div className="min-line"></div>
                                                    </div>
                                                </div>

                                                <button 
                                                    className={`rig-claim-button ${
                                                        (100 * currentFill[key] / getParams(key, 'capacity')) > 30 && !loadClaim[key] ? 'active' : ''}
                                                        ${loadClaim[key] ? 'loading' : ''}`}
                                                    disabled={(100 * currentFill[key] / getParams(key, 'capacity')) < 30 || loadClaim[key]}
                                                    onClick={() => setModalClaim(key)}
                                                >
                                                    {!loadClaim[key] && !modalClaim ? <span>{t('claim')}</span> :
                                                        <div className="loading-claim">
                                                            <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                            </svg>
                                                        </div>
                                                    }
                                                    
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : 
                                <div className="rig-overlay">
                                    <div className="rig-header">
                                        <span className="title">{t('oil-rig')} {key}</span>
                                        <button className="info-overlay" onClick={() => setModalInfoRig(key)}>i</button>
                                    </div>

                                    <div className="rig-bottom">
                                        <div className="requirements">
                                            {buildRequirements.map((req, index) => (
                                                <div key={index} className="requirement">
                                                        <div className='img-box'>
                                                            {req.resourceId ? <img src={resourceImages[req.resourceId]} alt={`Resource ${req.resourceId}`} /> :
                                                            <img src={componentImages[req.componentId]} alt={`Component ${req.componentId}`} />}
                                                        </div>
                                                    <span className='amount'>
                                                        {req.resourceId ? <span>{format(req.amount)}</span> : <span>{formatCapacity(req.amount)}</span> }
                                                    </span>
                                                </div>
                                            ))}
                                        </div>

                                        <button className={`build-button ${loadBuild[key] ? 'disabled' : ''}`} disabled={loadBuild[key]} onClick={() => buildRigFunc(key)}>
                                            {!loadBuild[key] ? 
                                                <span>{t('build')}</span> :
                                                <div className="loading-build">
                                                    <svg className="spinner" strokeWidth="65px" height="70%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                    </svg>
                                                </div>
                                            }
                                            
                                        </button>
                                    </div>

                                    
                                </div>
                            }

                            {showPyro[key] && (
                                <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            )}
                        </div>
                    );
                }
                return null; 
            })) 
            : 
                <div className="loading-animation">
                    <svg className="spinner" strokeWidth="65px" height="50%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            }
        </>
    );
};

export default Rigs;