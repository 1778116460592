
import React, { useEffect, useState } from 'react';
import './task-list.scss';
import task_list from './task_list.json';
import successIcon from './success.png';
import { format } from '../format-number';
import Alert from '../alerts';

import { useTonAddress } from '@tonconnect/ui-react';
import { useTonConnectUI } from '@tonconnect/ui-react';

import coinImage from '../navigation/airdrop.png';

import telegramIcon from './telegram.png';
import friendsIcon from './friends.png';
import getgemsIcon from './gg.png';
import crIcon from './cr.png';

import cityHolder from './cityHolder.png';
import bombie from './bombie.png';
import tokentails from './tokentails.png';
import dnevnikHamster from './dnevnikHamster.png';
import cryptoNewsToday from './cryptoNewsToday.png'
import kaboom from './kaboom.png'
import cryptoKitenok from './cryptoKitenok.png'
import moneyTube from './moneyTube.png'
import tonFlash from './tonFlash.png'
import XMeta from './XMeta.png'
import FruicyBlast from './FruicyBlast.png'
import PlantHarvest from './PlantHarvest.png'
import Batymchanin from './Batymchanin.png'
import DragTales from './DragTales.png'
import bombCrypto from './bombCrypto.png'
import greenThumb from './greenThumb.png'
import PAWS from './PAWS.png'
import TONLeitfaden from './TONLeitfaden.png'
import titan from './titan.png'
import manda from './manda.png'
import pawsCombat from './pawsCombat.png'
import pirateKing from './pirateKing.png'
import ancestral from './ancestral.png'
import dino from './dino.png'
import galacticGoldRush from './galacticGoldRush.png'

import { useTranslate } from '../translate/translate';
import { getTasks, completeTask, checkSubscribe, getUserInfo, checkNFT, changeWallet } from '../requests.jsx'

const iconMap = {
    telegramIcon: telegramIcon,
    friendsIcon: friendsIcon,
    getgemsIcon: getgemsIcon,
    crIcon: crIcon,
    cityHolder: cityHolder,
    bombie: bombie,
    tokentails: tokentails,
    dnevnikHamster: dnevnikHamster,
    cryptoNewsToday: cryptoNewsToday,
    kaboom: kaboom,
    cryptoKitenok: cryptoKitenok,
    moneyTube: moneyTube,
    tonFlash: tonFlash,
    XMeta: XMeta,
    FruicyBlast: FruicyBlast,
    PlantHarvest: PlantHarvest,
    Batymchanin: Batymchanin,
    DragTales: DragTales,
    bombCrypto: bombCrypto,
    greenThumb: greenThumb,
    PAWS: PAWS,
    TONLeitfaden: TONLeitfaden,
    titan: titan,
    manda: manda,
    pawsCombat: pawsCombat,
    pirateKing: pirateKing,
    ancestral: ancestral,
    dino: dino,
    galacticGoldRush: galacticGoldRush
};

const TaskList = () => {
    const t = useTranslate();
    const activeTasks = task_list.task_list.filter(task => task.active === true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [completedTasks, setCompletedTasks] = useState([]);

    const [tasksGroup, setTasksGroup] = useState([]);
    const [tasksGroupAmount, setTasksGroupAmount] = useState({});

    const [isChecking, setIsChecking] = useState({});

    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (window.tgID) {
            setDataLoaded(true);
        }
    }, [window.tgID]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataLoaded) {
                    const getTasksRes = await getTasks(window.tgID);
                    setCompletedTasks(getTasksRes.tasks);
                }
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        if (dataLoaded) {
            fetchData();
        }
    }, [dataLoaded]);

    useEffect(() => {
        const sortedTasks = [...activeTasks].sort((a, b) => {
            const aCompleted = completedTasks.includes(a.id);
            const bCompleted = completedTasks.includes(b.id);
            return aCompleted - bCompleted;
        });

        const groupTasks = (tasks) => {
            const groups = {
                'Social': [],
                'NFT Collection': [],
                'Friends': [],
                'Premium': [],
                'Partners': []
            };

            tasks.forEach(task => {
                if (task.type === 1.1 || task.type === 1.2) {
                    groups['Social'].push(task);
                } else if (task.type === 3.1 || task.type === 3.2) {
                    groups['NFT Collection'].push(task);
                } else if (task.type === 2) {
                    groups['Friends'].push(task);
                } else if (task.type === 1.4 || task.type === 1.41) {
                    groups['Premium'].push(task);
                } else if (task.type === 1.3) {
                    groups['Partners'].push(task);
                }    
            });

            return groups;
        };

        const groupedTasks = groupTasks(sortedTasks);
        setTasksGroup(groupedTasks);

        const groupAmounts = {};
        Object.keys(groupedTasks).forEach(group => {
            groupAmounts[group] = groupedTasks[group].filter(task => !completedTasks.includes(task.id)).length;
        });

        setTasksGroupAmount(groupAmounts);

    }, [completedTasks]);

    const orderedGroups = ['Social', 'Premium', 'NFT Collection', 'Friends', 'Partners'];

    const completionTask = async (task_id, coins, name) => {
        // Устанавливаем минимальное время выполнения в 2 секунды
        const minExecutionTime = new Promise(resolve => setTimeout(resolve, 2000));
        
        const tasks_res = await completeTask(window.tgID, task_id, coins, name);
        
        // Дожидаемся завершения 2-секундного таймера и ответа от completeTask
        await Promise.all([minExecutionTime, tasks_res]);
    
        if (tasks_res !== 'Already') {
            setCompletedTasks(tasks_res.tasks);
            setAlert('task-completed');
        } else {
            setAlert('task-already');
        }
    };
    
    const completeTaskF = async (task) => {
        setIsChecking(prev => ({ ...prev, [task.id]: true })); // Установка флага загрузки
    
        try {
            let taskPromise; // Промис для выполнения задачи
    
            // Проверка типа задачи и выполнение соответствующего действия
            if ([1.1, 1.2, 1.3, 1.4, 1.41].includes(task.type)) {
                if (task.type === 1.1) {
                    const chatId = '@container_rush';
                    const checkSubs = await checkSubscribe(window.tgID, chatId);
                    taskPromise = checkSubs
                        ? completionTask(task.id, task.reward, null)
                        : (window.Telegram.WebApp.openTelegramLink(task.url), setAlert('task-not-completed'));
                } else if (task.type === 1.2) {
                    const chatId = '@containerrush_chat';
                    const checkSubs = await checkSubscribe(window.tgID, chatId);
                    taskPromise = checkSubs
                        ? completionTask(task.id, task.reward, null)
                        : (window.Telegram.WebApp.openTelegramLink(task.url), setAlert('task-not-completed'));
                } else if (task.type === 1.3) {
                    window.Telegram.WebApp.openTelegramLink(task.url);
                    taskPromise = completionTask(task.id, task.reward, null);
                } else if (task.type === 1.4) {
                    const checkSubs = await checkSubscribe(window.tgID, task.chatId);
                    taskPromise = checkSubs
                        ? completionTask(task.id, task.reward, task.img)
                        : (window.Telegram.WebApp.openTelegramLink(task.url), setAlert('task-not-completed'));
                } else if (task.type === 1.41) {
                    window.Telegram.WebApp.openTelegramLink(task.url);
                    taskPromise = completionTask(task.id, task.reward, null);
                }
            } else if (task.type === 2) { // Задание на друзей
                await getUserInfo(window.tgID);
                taskPromise = window.refs >= task.friends
                    ? completionTask(task.id, task.reward, null)
                    : setAlert('task-not-completed');
            } else if ([3.1, 3.2].includes(task.type)) { // Задание на наличие НФТ
                if (window.wallet) {
                    const nftType = task.type === 3.1 ? 1 : 2;
                    const nftCheckLink = task.type === 3.1
                        ? 'https://getgems.io/collection/EQAjt0VJaaV2bmfO5QuhenHTly5P7-LvO5cR4w59P1h4ruhI'
                        : 'https://getgems.io/collection/EQAgILmPB2faA145DlhrJ1pmDF6RHaToe4AOaPrOchfnWNk_';
    
                    const res = await checkNFT(window.wallet, nftType);
                    taskPromise = (res !== 'False') ? completionTask(task.id, task.reward, null) :
                        (window.Telegram.WebApp.openLink(nftCheckLink), setAlert('task-not-completed'));
                } else {
                    await tonConnectUI.openModal();
                }
            }
    
            // Выполняем задачу
            await taskPromise;
    
        } finally {
            setIsChecking(prev => ({ ...prev, [task.id]: false })); // Снятие флага загрузки после завершения
        }
    };


    const renderTaskListBox = (group) => (
        <div key={group} className="task-list-box">

            <div className={`tasks-title ${tasksGroupAmount[group] > 0 ? '' : 'completed'}`}>
                <span className='tasks-title-text'>{t(group)}</span>
                <span className='tasks-amount'>{tasksGroupAmount[group] > 0 ? tasksGroupAmount[group] : 'Выполнено'}</span>
            </div>  
            
            <div className='task-list'>
                {tasksGroup[group].map((task, index) => (
                    <div className={`task-item ${completedTasks.includes(task.id) ? 'completed' : ''} ${group === 'Premium' ? 'premium' : ''}`} key={index}>
                        <img src={iconMap[task.img]} alt='Task' />
                        <div className='task-text-box'>
                            <span className='task-text'>{t(task.text)}</span>
                            <div className='reward-box'>
                                <div className='reward-item'>
                                    <div className='reward-item-img-box'>
                                        <img src={coinImage} alt='$SEACOIN' />
                                    </div>
                                    <div className='reward-item-amount-box'>
                                        {task.reward}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {completedTasks.includes(task.id) ? (
                            <img className='success-img' src={successIcon} alt='Task completed' />
                        ) : (
                            <button className={`task-button ${isChecking[task.id] ? 'disabled' : ''}`} onClick={() => completeTaskF(task)}>
                                {isChecking[task.id] ? (
                                    <div className="loading-animation-check">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) : (
                                    t("start")
                                )}
                            </button>
                        )}
                        
                    </div>
                ))}
            </div>  
        </div>
    );
    

    return (
        <>
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}

            <div>
                {orderedGroups.map(group => {
                    if (tasksGroupAmount[group] > 0) {
                        return renderTaskListBox(group);
                    }
                    return null;
                })}
                {orderedGroups.map(group => {
                    if (tasksGroupAmount[group] === 0) {
                        return renderTaskListBox(group);
                    }
                    return null;
                })}
            </div>
        </>   
    );
};

export default TaskList;