import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './server-error.scss';
import { useTranslate } from './translate/translate';

import ErrorIcon from './error.png';

const ServerErrorModal = ({ serverError }) => {
    const t = useTranslate();

    useEffect(() => {
        setTimeout(() => {
            window.Telegram.WebApp.close();
        }, 3000);
    }, []);

    return (
        <>
            {serverError && ReactDOM.createPortal(
                <div className='server-error-overlay'>
                    <div className='box'>
                        <img src={ErrorIcon} alt='Error' />
                        <span>{t('server-error')}</span>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default ServerErrorModal;