
import axios from 'axios';
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY_CRYPT;

// Функция для шифрования данных
function encryptData(data) {
    const iv = CryptoJS.lib.WordArray.random(16);
    const jsonString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(jsonString, CryptoJS.enc.Hex.parse(secretKey), { iv }).toString();
    return iv.toString() + ':' + encrypted;
}

// Функция для расшифровки данных
function decryptData(encryptedData) {
    const [ivHex, encrypted] = encryptedData.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    
    const decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS.enc.Hex.parse(secretKey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

// CSRF Token
export const getCSRFToken = () => {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('csrftoken='))
        ?.split('=')[1];
    return cookieValue || '';
};

// Время на сервере
export const getTimeServer = async () => {
    try {
        const response = await axios.get('https://cont-rush.online/api/time/', {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        return decryptData(response.data);
    } catch (error) {
        console.error('Error time server:', error);
    }
};

export const login = async (tgID) => {
    try {
        const oneTimeToken = await getOneTimeToken();  // Получаем одноразовый токен
        const sessionToken = localStorage.getItem('session_token');
        const encryptedData = encryptData({ tgID });

        const res = await axios.post('https://cont-rush.online/api/login/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(res.data.session_token);
        if (decryptedResponse !== 'error') {
            localStorage.setItem('session_token', decryptedResponse);
        }
    } catch (error) {
        console.error('Error session', error);
        return 'error';
    }
};

export const fetchToken = async (tgID) => {
    try {
        const encryptedData = encryptData({ tgID });

        const res = await axios.get('https://cont-rush.online/api/fetch-token/', {
            params: { data: encryptedData },
            headers: {
                'Authorization': localStorage.getItem('session_token'),
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        return res.data;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            window.Telegram.WebApp.close();
        }
    }
};


///////////////////////////////////////////// Язык /////////////////////////////////////////////////////////////
// Смена языка
export const changeLanguageRequest = async (tgID) => {
    try {
        const encryptedData = encryptData({ tgID });
        const oneTimeToken = await getOneTimeToken();  // Получаем одноразовый токен
        const sessionToken = localStorage.getItem('session_token');
        const res = await axios.post('https://cont-rush.online/api/change-language-request/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(res.data);
        return decryptedResponse;
    } catch (error) {
        console.error('Error making POST request about change language:', error);
    }
};

// Загрузка языка
export const getLanguageRequest = async (tgID) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-language-request/', {
            params: { data: encryptedData },
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        const decryptedResponse = decryptData(res.data);
        return decryptedResponse;
    } catch (error) {
        console.error('Error making GET request about language:', error);
    }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Подключение пользователя
export const sendDataToServer = async (wallet, tgID, referer, firstname, lastname) => {
    fetchToken(window.tgID);
    const encryptedData = encryptData({ wallet, tgID, referer, firstname, lastname });
    try {
        const response = await axios.post('https://cont-rush.online/api/user-connect/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(response.data);
        // Обновить все переменные
        window.id = decryptedResponse.user.id;
        window.lvl_conts = decryptedResponse.user.lvl_conts;
        window.resources = decryptedResponse.user.resources;
        window.components = decryptedResponse.user.components;
        window.lvl = decryptedResponse.user.lvl;
        window.lvl_store = decryptedResponse.user.lvl_store;
        window.xp = decryptedResponse.user.xp;
        window.voyage_conts = decryptedResponse.user.voyage_conts;
        window.mg_time = decryptedResponse.user.mg_time;
        window.avatar = decryptedResponse.user.avatar;
        window.tutorial = decryptedResponse.user.tutorial;
        window.resources_market = decryptedResponse.user.resources_market ;
        window.components_market  = decryptedResponse.user.components_market;
        window.rigs = decryptedResponse.user.oil_rigs;

        window.referer = decryptedResponse.user.referer;
        window.referer_reward = decryptedResponse.user.referer_reward;
        window.refs = decryptedResponse.user.refs;
        window.refs_reward = decryptedResponse.user.refs_reward;

        window.default_ship = decryptedResponse.user.default_ship;
        window.default_conts = decryptedResponse.user.default_conts;
        window.default_conts_levelup = decryptedResponse.user.default_conts_levelup;

        window.event_view = decryptedResponse.user.event_view;
        window.event_voyages = decryptedResponse.user.event_voyages;
        window.event_points = decryptedResponse.user.event_points;

        window.coins = decryptedResponse.user.coins;
        
        return decryptedResponse.disconnect;
    } catch (error) {
        console.error('Error making POST request about user connect:', error);
    }
};

// Получение всей информации о пользователе
export const getUserInfo = async (tgID) => {
    fetchToken(window.tgID);
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-user-info/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(res.data);
        // Обновить все переменные
        window.id = decryptedResponse.id;
        window.resources = decryptedResponse.resources;
        window.components = decryptedResponse.components;
        window.lvl_conts = decryptedResponse.lvl_conts;
        window.lvl = decryptedResponse.lvl;
        window.xp = decryptedResponse.xp;
        window.lvl_store = decryptedResponse.lvl_store
        window.voyage_conts = decryptedResponse.voyage_conts;
        window.avatar = decryptedResponse.avatar;
        window.tutorial = decryptedResponse.tutorial;
        window.resources_market = decryptedResponse.resources_market;
        window.components_market = decryptedResponse.components_market;
        window.rigs = decryptedResponse.oil_rigs;

        window.referer = decryptedResponse.referer;
        window.referer_reward = decryptedResponse.referer_reward;
        window.refs = decryptedResponse.refs;
        window.refs_reward = decryptedResponse.refs_reward;

        window.default_ship = decryptedResponse.default_ship;
        window.default_conts = decryptedResponse.default_conts;
        window.default_conts_levelup = decryptedResponse.default_conts_levelup;

        window.event_view = decryptedResponse.event_view;
        window.event_voyages = decryptedResponse.event_voyages;
        window.event_points = decryptedResponse.event_points;

        window.coins = decryptedResponse.coins;

    } catch (error) {
        console.error('Error making GET request about user info:', error);
    }
}

// Подключение пользователя
export const changeWallet = async (tgID, wallet) => {
    const encryptedData = encryptData({ tgID, wallet });
    try {
        const res = await axios.post('https://cont-rush.online/api/change-wallet/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(res.data);
        window.wallet = decryptedResponse.wallet;
        return decryptedResponse.disconnect;
    } catch (error) {
        console.error('Error making POST request about change wallet:', error);
    }
};

// Информация о наличии у пользователя НФТ контейнеров
export const getContainers = async (tgID, wallet, location) => {
    const encryptedData = encryptData({ tgID, wallet, location });
    try {
        const res = await axios.post('https://cont-rush.online/api/get-containers/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        const decryptedResponse = decryptData(res.data);

        window.cont_blue = decryptedResponse.Blue
        window.cont_red = decryptedResponse.Red
        window.cont_green = decryptedResponse.Green
        window.cont_bronze = decryptedResponse.Bronze
        window.cont_silver = decryptedResponse.Silver
        window.cont_gold = decryptedResponse.Gold

        return decryptedResponse;
    } catch (error) {
        console.error('Error making GET request about containers:', error);
    }
};

// Изменение уровня контейнера
export const changeContLvl = async (tgID, cont, moneyCost, componentsCost) => {
    try {
        const encryptedData = encryptData({ tgID, cont, moneyCost, componentsCost });
        const oneTimeToken = await getOneTimeToken();
        const sessionToken = localStorage.getItem('session_token');
        const res = await axios.post(
            'https://cont-rush.online/api/change-cont-lvl/', { data: encryptedData, one_time_token: oneTimeToken },
            {
                headers: {
                    'X-CSRFToken': getCSRFToken(),
                    'Authorization': sessionToken,
                },
                withCredentials: true,
            }
        );

        const decryptedResponse = decryptData(res.data);
        window.lvl_conts = decryptedResponse;
    } catch (error) {
        console.error('Error making POST request about change container level:', error);
    }
};

// Присвоение аватарки
export const avatarChoose = async (tgID, avatar) => {
    const encryptedData = encryptData({ tgID, avatar });
    try {
        const res = await axios.post('https://cont-rush.online/api/avatar-choose/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        const decryptedResponse = decryptData(res.data);
        window.avatar = decryptedResponse;
    } catch (error) {
        console.error('Error making POST request about change resources:', error);
    }
};

// Закночить обучение
export const tutorialEnd = async (tgID) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.post('https://cont-rush.online/api/tutorial-end/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about end tutorial:', error);
    }
};

/////////////////////////////////////////////////////// Маркет //////////////////////////////////////////////////////////// 

// Создание лота
export const marketAdd = async (tgID, wallet_seller, type, price, number, value, elements, minimum) => {
    const encryptedData = encryptData({ tgID, wallet_seller, type, price, number, value, elements, minimum });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        await axios.post('https://cont-rush.online/api/market-add/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about add market lot:', error);
    }
};

// Получение твоих лотов
export const marketGetMyLots = async (tgID) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/market-get-my-lots/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about my lots:', error);
    }
};

// Отмена лота
export const cancelLot = async (id) => {
    const encryptedData = encryptData({ id });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/market-cancel-lot/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.resources = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about cancel lot:', error);
    }
};

// Поиск лотов для покупки
export const foundLots = async (type, number) => {
    const encryptedData = encryptData({ type, number });
    try {
        const res = await axios.get('https://cont-rush.online/api/market-found-lots/', {
            params: { data: encryptedData },
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about found market lots:', error);
    }
};

// Проверка состояния views лота
export const getViewsLot = async (id) => {
    const encryptedData = encryptData({ id });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-views-lot/', {
            params: { data: encryptedData },
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get lot:', error);
    }
};

// Покупка лота
export const buyLot = async (id, wallet, walletBC, price, transactionID) => {
    const encryptedData = encryptData({ id, wallet, walletBC, price, transactionID });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/buy-lot/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about buy lot:', error);
    }
};

// Проверка состояния лота во время покупки
export const buyLotCheck = async (id) => {
    const encryptedData = encryptData({ id });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/buy-lot-check/', { data: encryptedData, one_time_token: oneTimeToken },
            {
                headers: {
                    'X-CSRFToken': getCSRFToken(),
                    'Authorization': sessionToken,
                },
                withCredentials: true,
            });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about check buying lot:', error);
    }
};

// Сменить views лота на False (в случае отмены транзакции)
export const setViewsLot = async (id) => {
    const encryptedData = encryptData({ id });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/set-views-lot/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about set views lot:', error);
    }
};

// Поиск проданных лотов
export const getSoldLots = async (tgID) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/search-sold-lots/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get sold lots:', error);
    }
};

// Удаление проданных лотов
export const deleteSoldLots = async (ids) => {
    const encryptedData = encryptData({ ids });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/delete-sold-lots/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about delete sold lots:', error);
    }
};
////////////////////////////////////////////////// КОРАБЛИ /////////////////////////////////////////////////////////////

export const getShipsWithRented = async ( tgID, wallet ) => {
    const encryptedData = encryptData({ tgID, wallet });
    try {
        const res = await axios.post('https://cont-rush.online/api/get-ships-with-rented/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error get ships with rented:', error);
    }
};

// Изменение уровня корабля
export const changeShipLvl = async (tgID, ship, moneyCost, componentsCost) => {
    const encryptedData = encryptData({ tgID, ship, moneyCost, componentsCost });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/change-ship-lvl/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about change ship level:', error);
    }
};

// Изменение цены аренды корабля
export const changeShipPrice = async (id, price) => {
    const encryptedData = encryptData({ id, price });
    try {
        const res = await axios.post('https://cont-rush.online/api/change-ship-price/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about change ship level:', error);
    }
};

// Получение информации о всех кораблях
export const getAllShips = async () => {
    try {
        const res = await axios.get('https://cont-rush.online/api/get-all-ships/', {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get all ships:', error);
    }
};

// Получение корабля по id
export const getShipById = async (id) => {
    const encryptedData = encryptData({ id });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-ship-by-id/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get ship by id:', error);
    }
};

///////////////////////////////////////////  РЕЙСЫ //////////////////////////////////////////////

// Отправить в рейс
export const toVoyage = async ( tgID, costFuel, conts, id, timer, country, xp, resources ) => {
    const encryptedData = encryptData({ tgID, costFuel, conts, id, timer, country, xp, resources });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/to-voyage/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about send to voyage:', error);
    }
};

// Найти мои рейсы
export const getMyVoyages = async ( tgID ) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-my-voyages/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get my voyages:', error);
    }
};

// Завершить рейс
export const endVoyage = async ( tgID, resources, idVoyage ) => {
    const encryptedData = encryptData({ tgID, resources, idVoyage });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/end-voyage/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about send containers to voyage:', error);
    }
};

/////////////////////////////////////// ПОРТ /////////////////////////////////////////

///////////////////////////////// АРЕНДА КОРАБЛЕЙ //////////////////////////////////

// Поиск кораблей в ожидании аренды
export const getRentShipsByType = async ( type ) => {
    const encryptedData = encryptData({ type });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-rent-ships-by-type/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get rent ships by type:', error);
    }
};

// Проверка состояния views корабля
export const getViewsShip = async ( name ) => {
    const encryptedData = encryptData({ name });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-views-ship/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get views ship:', error);
    }
};

// Аренда корабля
export const rentShip = async ( name, wallet, walletBC, price, transactionID ) => {
    const encryptedData = encryptData({ name, wallet, walletBC, price, transactionID });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/rent-ship/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about rent ship:', error);
        return null;
    }
};

// Сменить views корабля на False (в случае отмены транзакции)
export const setViewsShip = async (ship) => {
    const encryptedData = encryptData({ ship });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/set-views-ship/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about set views ship:', error);
    }
};

// Проверка состояния лота во время покупки
export const rentCheck = async (ship) => {
    const encryptedData = encryptData({ ship });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/rent-check/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about check rent ship:', error);
    }
};

// Поиск кораблей, сданных в аренду (для уведомления)
export const getRentedShips = async ( wallet ) => {
    const encryptedData = encryptData({ wallet });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-rented-ships/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get rented ships:', error);
    }
};

// Rented=False для кораблей (для уведомления)
export const confirmRentedShips = async ( ids ) => {
    const encryptedData = encryptData({ ids });
    try {
        const res = await axios.post('https://cont-rush.online/api/confirm-rented-ships/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about confirm rented ships:', error);
    }
};

//////////////////////////////////////// ПРОФИЛЬ ///////////////////////////////////////////////

// Окончание мини-игры: Забрать/проигрыш
export const mgOver = async ( tgID, trap, resources ) => {
    const encryptedData = encryptData({ tgID, trap, resources });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/mg-over/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about mini-game over:', error);
        return null;
    }
};

// Проверка доступности мини-игры
export const getMgAvailable = async ( tgID ) => {
    fetchToken(window.tgID);
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-mg-available/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get mini-game available:', error);
    }
};

// Получение информации о заданиях
export const getTasks = async ( tgID ) => {
    fetchToken(window.tgID);
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-tasks/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get tasks:', error);
    }
};

// Выполнить задание
export const completeTask = async ( tgID, task, reward, namePaid ) => {
    const encryptedData = encryptData({ tgID, task, reward, namePaid });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/complete-task/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about complete task:', error);
    }
};

export const checkSubscribe = async ( userId, chatId ) => {
    const encryptedData = encryptData({ userId, chatId });
    try {
        const res = await axios.post('https://cont-rush.online/api/check-sub/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about check subscribe:', error);
    }
};

// Получние количества сминченных НФТ
/* export const getMinted = async ( collection ) => {  // 1 - корабли, 2 - контейнеры
    const url = collection === 1 ? '0:a363a2d20f634087acc3f8829b4e610a5ecef7697be90acda7af80bb0ad17a2a' : 
                collection === 2 ? '0:32133bf31502186dfe1eef82b974fbd97459eafb9638e86542c28eb0a0493e2c' : '';
    try {
        const res = await axios.get(`https://tonapi.io/v2/blockchain/accounts/${url}/methods/get_types_counter`);
        console.log(res.data)
        return res.data.stack.map(item => item.num.split('x')[1]); //[0, 0, 0, 0, 0]
    } catch (error) {
        console.error('Error making GET request about get ships minted:', error);
    }
}; */

export const getMinted = async ( collection ) => {
    const encryptedData = encryptData({ collection });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-minted/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get minted nfts:', error);
    }
};

// Дефолтные контейнеры
export const addDefaultConts = async ( tgID, cont ) => {
    const encryptedData = encryptData({ tgID, cont });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/add-default-conts/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.default_conts = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about add default container:', error);
    }
};

// Дефолтные корабли
export const addDefaultShip = async ( tgID, ship ) => {
    const encryptedData = encryptData({ tgID, ship });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/add-default-ship/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.default_ship = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about add default ship:', error);
    }
};

// Получение рейтинга
export const getRating = async ( tgID ) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-rating/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get rating:', error);
    }
};

// Проверка задания на наличие НФТ
export const checkNFT = async ( wallet, collection ) => {
    const encryptedData = encryptData({ wallet, collection });
    try {
        const res = await axios.get('https://cont-rush.online/api/check-nft/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get rating:', error);
    }
};

/////////////////////////////////////////////// TEMP 1 /////////////////////////////////////////

// Первый раз посмотрел инфу по ивенту
export const changeEventView = async ( tgID, event ) => {
    const encryptedData = encryptData({ tgID, event });
    try {
        const res = await axios.post('https://cont-rush.online/api/set-event-view/', { data: encryptedData }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });

        const decrypted_data = decryptData(res.data);
        window.event_view = decrypted_data.event_view;
        window.event_voyages = decrypted_data.event_voyages;
        window.event_points = decrypted_data.event_points;
    } catch (error) {
        console.error('Error making POST request about set event view:', error);
    }
};

// Отправил рейс в ивенте
export const changeEventData = async ( tgID, event, voyage, points ) => {
    const encryptedData = encryptData({ tgID, event, voyage, points });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/set-event-data/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        const decrypted_data = decryptData(res.data);
        window.event_voyages = decrypted_data.event_voyages;
        window.event_points = decrypted_data.event_points;
    } catch (error) {
        console.error('Error making POST request about set event data:', error);
    }
};

// Получение рейтинга Temp1
export const getTemp1Rating = async ( tgID ) => {
    const encryptedData = encryptData({ tgID });
    try {
        const res = await axios.get('https://cont-rush.online/api/get-temp1-rating/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about get rating:', error);
    }
};

//////////////////////////////////// Компоненты /////////////////////////////////////

// Получение одноразового токена
const getOneTimeToken = async () => {
    const sessionToken = localStorage.getItem('session_token');
    const response = await axios.get('https://cont-rush.online/api/generate-token/', { 
        headers: {
            'Authorization': sessionToken,
        },
        withCredentials: true 
    });
    return response.data.one_time_token;
};

// Создание компонента
export const createComponent = async ( tgID, component, resourcesCost, componentsCost ) => {
    const encryptedData = encryptData({ tgID, component, resourcesCost, componentsCost });
    const oneTimeToken = await getOneTimeToken();  // Получаем одноразовый токен
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/create-component/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });

        const decrypted_data = decryptData(res.data);
        window.resources = decrypted_data.resources;
        window.components = decrypted_data.components;
    } catch (error) {
        console.error('Error making POST request about create component:', error);
    }
};

/////////////////////////////////// Склад /////////////////////////////////////////////

// Повышение уровня склада
export const upgradeStore = async ( tgID, resources, components ) => {
    const encryptedData = encryptData({ tgID, resources, components });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/upgrade-store/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });

        const decrypted_data = decryptData(res.data);
        window.resources = decrypted_data.resources;
        window.components = decrypted_data.components;
        window.lvl_store = decrypted_data.lvl_store;
    } catch (error) {
        console.error('Error making POST request about upgrade store:', error);
    }
};

///////////////////////////////// НЕФТЯНЫЕ ВЫШКИ ///////////////////////////////////////

// Построить вышку
export const buildRig = async ( tgID, rig, moneyCost, componentsCost ) => {
    const encryptedData = encryptData({ tgID, rig, moneyCost, componentsCost });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/build-rig/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.rigs = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about build rig:', error);
    }
};

export const changeRigLvl = async ( tgID, rig, param, moneyCost, componentsCost, currentFill ) => {
    const encryptedData = encryptData({ tgID, rig, param, moneyCost, componentsCost, currentFill });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/upgrade-rig/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.rigs = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about upgrade rig:', error);
    }
};

export const startRig = async ( tgID, rig ) => {
    const encryptedData = encryptData({ tgID, rig });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/start-rig/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.rigs = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about start mine rig:', error);
    }
};

export const claimRig = async ( tgID, rig, amount ) => {
    const encryptedData = encryptData({ tgID, rig, amount });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/claim-rig/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        window.rigs = decryptData(res.data);
    } catch (error) {
        console.error('Error making POST request about claiming rig:', error);
    }
};

export const rigClaimTon = async ( tgID, wallet, game_wallet, rig, amount, transactionID ) => {
    const encryptedData = encryptData({ tgID, wallet, game_wallet, rig, amount, transactionID });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');

    try {
        const res = await axios.post('https://cont-rush.online/api/rig-claim-ton/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
    } catch (error) {
        console.error('Error making POST request about buy x2 fuel:', error);
    }
};

// Проверка состояния платежа для Х2
export const checkX2ClaimRig = async (wallet, transactionID) => {
    const encryptedData = encryptData({ wallet, transactionID });
    try {
        const res = await axios.get('https://cont-rush.online/api/check-x2/', {
            params: { data: encryptedData }, 
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
            withCredentials: true,
        });
        return decryptData(res.data);
    } catch (error) {
        console.error('Error making GET request about check X2 claim:', error);
    }
};

///////////////////////////////////// AIRDROP /////////////////////////////////////////

// Обменять ресурсы/компоненты на токен
export const setUserCoins = async ( tgID, type, number, count, coins ) => {
    const encryptedData = encryptData({ tgID, type, number, count, coins });
    const oneTimeToken = await getOneTimeToken();
    const sessionToken = localStorage.getItem('session_token');
    try {
        const res = await axios.post('https://cont-rush.online/api/set-coins/', { data: encryptedData, one_time_token: oneTimeToken }, 
            {
            headers: {
                'X-CSRFToken': getCSRFToken(),
                'Authorization': sessionToken,
            },
            withCredentials: true,
        });
        const decrypted_data = decryptData(res.data);

        if (decrypted_data !== 'error') {
            window.resources = decrypted_data.resources;
            window.components = decrypted_data.components;
            window.coins = decrypted_data.coins;
            return 'True';
        } else {
            return 'False';
        }
        
    } catch (error) {
        console.error('Error making POST request about add coins:', error);
    }
};