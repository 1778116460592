import React, { useEffect, useRef, useState } from 'react';
import Loading from './components/loading';

import './components/translate/translate';

import './App.scss';
import Navigation from './components/navigation/navigation';
import Tutorial from './components/tutorial/tutorial';

import DefaultGetWindow from './components/default/get';
import ServerErrorModal from './components/server-error';

import techWorksImage from './components/tech_works.png';

import { login, fetchToken } from './components/requests';

window.Telegram.WebApp.expand();
window.Telegram.WebApp.ready();
window.Telegram.WebApp.disableVerticalSwipes();
window.Telegram.WebApp.enableClosingConfirmation();

const viewportHeight = window.Telegram.WebApp.viewportStableHeight;

document.documentElement.style.height = `${viewportHeight}px`;
document.body.style.height = `${viewportHeight}px`;

window.tgID = window.Telegram.WebApp.initDataUnsafe.user.id;

if (window.Telegram.WebApp.initDataUnsafe.start_param) {
    window.referer = window.Telegram.WebApp.initDataUnsafe.start_param;
}

if (window.Telegram.WebApp.initDataUnsafe.user.first_name) {
    window.firstname = window.Telegram.WebApp.initDataUnsafe.user.first_name;
    if (window.Telegram.WebApp.initDataUnsafe.user.last_name) {
        window.lastname = window.Telegram.WebApp.initDataUnsafe.user.last_name;
    }
}

/* window.tgID = 7158881931; //1800928437  7158881931  6158281931
window.referer = 12;
window.firstname = 'TEST';   
window.lastname = ''; */

function App() {
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [tutorial, setTutorial] = useState(false);
    const [step, setStep] = useState(1);
    const [defaultWindowShip, setDefaultWindowShip] = useState(false);
    const [defaultWindowConts, setDefaultWindowConts] = useState(false);
    const [serverError, setServerError] = useState(false);

    const [password, setPassword] = useState('');
    const [unlock, setUnlock] = useState(true);
    const correctPassword = '4426';

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        if (password === correctPassword) {
          setUnlock(true);
        } else {
          return
        }
    };

    useEffect(() => {
        const loginAndFetchToken = async () => {
            try {
                const loginResponse = await login(window.tgID);
                if (loginResponse === 'error') {
                    setServerError(true);
                }

                await fetchToken(window.tgID);
            } catch (error) {
                console.error('Error session', error);
            }
        };
    
        loginAndFetchToken();
    }, [window.tgID]);

    useEffect(() => {
        const handleResize = () => {
            if (contentRef.current) {
                contentRef.current.style.height = window.innerHeight + 'px';
            }
        };
        handleResize();
    }, []);

    useEffect(() => {
        if (window.default_ship !== 'undefined' && window.default_ship == 0) {setDefaultWindowShip(true)};
        if (window.default_conts && window.default_conts.every(value => value === 0)) {setDefaultWindowConts(true)};
        if (window.default_conts_levelup > 0) {setDefaultWindowConts(true)};
    }, [window.default_ship, window.default_conts, window.default_conts_levelup]);

    return (
        <div className='App' ref={contentRef}>

            {serverError && <ServerErrorModal serverError={serverError}/>}

            {!unlock && !serverError && (
                <div className='tech-works'>
                    <img src={techWorksImage} alt="Technical Works" />
                    <span className='tech-works-title'>
                        Technical works
                    </span>

                    <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter password"
                    />
                    <button type="submit">Check</button>
                    </form>
                </div>
            )}

            {unlock && !serverError && (
                loading ? (
                    <Loading loading={loading} setLoading={setLoading} setTutorial={setTutorial}/>
                ) : (
                    <>
                    {tutorial ? (<Tutorial tutorial={tutorial} setTutorial={setTutorial} step={step} setStep={setStep} />) : null}

                    {defaultWindowShip ? (<DefaultGetWindow type='ship' setDefaultWindowShip={setDefaultWindowShip}/>) : null}
                    {defaultWindowConts ? (<DefaultGetWindow type='conts' setDefaultWindowConts={setDefaultWindowConts} levelup={window.default_conts_levelup > 0}/>) : null}

                    <Navigation setStep={setStep} setTutorial={setTutorial}/> 
                    </>
                )
            )}
        </div>
        
    );
}

export default App;
