
import React, { useState, useEffect, useRef } from 'react';
import './ships.scss';
import { useTranslate } from '../../translate/translate';
import { getUserInfo, getShipsWithRented, changeShipPrice,getTimeServer } from '../../requests';

import Rented from '../rent/rented';
import MintShipsPage from './mint-ships';
import RentModal from './rent_out';
import UpgradeModal from './upgrade';
import SoonAlert from '../../technologies/soon';
import InfoShips from './info';

import config from './ships_upgrade.json';
import capacityImage from './cont-icon.png'
import RentImage from '../rent.png';
import backImage from '../back.png';
import emptyImage from '../../technologies/empty.png';
import TonImage from '../../market/ton.png';

import PioneerImage from './pioneer.png';
import ExplorerImage from './explorer.png';
import VoyagerImage from './voyager.png';
import OdysseyImage from './odyssey.png';
import TitanImage from './titan.png';

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const Ships = ({ setActiveSection }) => {
    const t = useTranslate();
    const [serverTime, setServerTime] = useState(null);
    const serverTimeRef = useRef(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [ships, setShips] = useState([]);
    const [modalRent, setModalRent] = useState(false);
    const [modalUpgrade, setModalUpgrade] = useState(false);
    const [info, setInfo] = useState(false);

    const [selectedShip, setSelectedShip] = useState(null);
    const [mintPage, setMintPage] = useState(false);
    const [soonAlert, setSoonAlert] = useState(null);

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs; // Сохраняем в ref для обновлений каждую секунду
    };

    useEffect(() => {
        let syncIntervalId;
        let tickIntervalId;

        fetchServerTime();

        syncIntervalId = setInterval(fetchServerTime, 5000);

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(syncIntervalId);
            clearInterval(tickIntervalId);
        };
    }, []);

    const getShipCapacity = (shipType, level) => {
        const shipConfig = config.ships.find(ship => ship.type === shipType);
        if (shipConfig) {
            const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
            return levelConfig && levelConfig.capacity;
        }
    };

    const updateShips = async () => {
        if (window.wallet) {
            const responseShips = await getShipsWithRented(window.tgID, window.wallet);
            setShips(responseShips);
        } else {
            const responseShips = await getShipsWithRented(window.tgID, null);
            setShips(responseShips);
        }
        await getUserInfo(window.tgID);
        setDataLoaded(true);
    };

    useEffect(() => {
        updateShips();
    }, []);

    const rentOutShipWindow = (ship) => {
        setSelectedShip(ship);
        setModalRent(true);
    };

    const upgradeButton = (ship) => {
        setSelectedShip(ship);
        setModalUpgrade(true);
    };

    const infoButton = (ship) => {
        setSelectedShip(ship);
        setInfo(true);
    };

    const rentOutConfirm = async (id, price) => {
        const changeShipPriceResponse = await changeShipPrice(id, price);
        
        if (changeShipPriceResponse) {
            setShips(prevShips => 
                prevShips.map(ship => 
                    ship.id === id ? { ...ship, price_rent: changeShipPriceResponse } : ship
                )
            );
        }
        await updateShips();
    };

    const formatTimeDifference = (endTime) => {
        const end = new Date(endTime);
        if (end <= serverTime) {
            return t('no')
        }
        const diff = end - serverTime;
        const hours = Math.floor(diff / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const soonClick = (event) => {
        const { clientX, clientY } = event;
        setSoonAlert({ page: 'ship', x: clientX, y: clientY });
    };

    const ShipBox = ({ ship }) => {

        const [rentTimeShip, setRentTimeShip] = useState(formatTimeDifference(ship.rent));
        const [travelTimeShip, setTravelTimeShip] = useState(formatTimeDifference(ship.travel));
        
        useEffect(() => {
            const rentInterval = setInterval(() => {
                setRentTimeShip(formatTimeDifference(ship.rent));
            }, 1000);
    
            const travelInterval = setInterval(() => {
                setTravelTimeShip(formatTimeDifference(ship.travel));
            }, 1000);

            return () => {
                clearInterval(rentInterval);
                clearInterval(travelInterval);
            };
        }, [ship.rent, ship.travel]);

        if (!ship) return null;
        const currentShip = config.ships.find(s => s.type === ship.type);
        if (!currentShip) return null;
    
        const currentCapacity = getShipCapacity(ship.type, ship.lvl);

        const travelEndTime = new Date(ship.travel);
        const rentEndTime = new Date(ship.rent);

        const isRentedOut = ship.wallet_rent && ship.wallet_rent != window.wallet && rentEndTime > serverTime;
        const isRented = ship.wallet_rent && ship.wallet_rent == window.wallet && rentEndTime > serverTime;

        const isTraveling = travelEndTime > serverTime;

        return (
            <div id={ship.id} className="ship-item">
                <div className="ship-img-box">
                    <img src={shipImages[ship.type]} alt={ship.type} className="ship-image" />
                    <div className="ship-name">{ship.name}</div>
                    <div className="ship-lvl">{t('lvl')} {ship.lvl}</div>
                    <div className="ship-capacity">
                        {currentCapacity}
                        <img src={capacityImage} alt='capacity'/>
                    </div>
                    {isRented && (
                        <div className="ship-rented">
                            <img src={RentImage} alt="Rented" />
                        </div>
                    )}
                </div>

                {isRented ? (
                    <div className="is-rented-info">
                        <div className="is-rented-voyages-left">{t("ost-voyages")}: 1</div>
                        <div className="is-rented-timer">
                            <span>{t("time-return")}</span>
                            <span>{rentTimeShip}</span>
                        </div>
                    </div>
                ) : (
                    <div className="right-ship">
                        <div className="technologies-box">
                            <button className="technologie" onClick={soonClick}>
                                <img src={emptyImage} alt="Technologie" />
                            </button>
                            <button className="technologie" onClick={soonClick}>
                                <img src={emptyImage} alt="Technologie" />
                            </button>
                        </div>

                        <div className="buttons">
                            {ship.lvl != 10 && <button className={`upgrade-button ${(isRented || ship.price_rent > 0 || isTraveling || isRentedOut) ? 'disabled' : ''}`} onClick={() => upgradeButton(ship)}>{t('upgrade')}</button>}   
                            <button className={`rent-out-button ${ship.default  ? 'disabled' : ''}`} onClick={() => rentOutShipWindow(ship)}>
                                <img src={RentImage} alt="Rent" />
                                <span>{t('rent-out')}</span>
                            </button>
                        </div>
                        
                        {/* Корабль в ожидании аренды */}
                        {ship.price_rent > 0 && (
                            <div className="ship-rent-price">
                                <div className="ship-rent-price-text">
                                    <span>{t('ship-rent-price-text')}</span>
                                    <div className="ship-rent-price-text2">
                                        <img src={TonImage} alt="Price" />
                                        <span>{ship.price_rent}</span>
                                    </div>
                                    <button className="ship-rent-cancel-button" onClick={() => rentOutConfirm(ship.id, 0)}>
                                        {t('cancel')}
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Корабль в рейсе */}
                        {isTraveling && (
                            <div className="ship-travel-info">
                                <div className="ship-travel-info-box">
                                    <span>{t('on-voyage')}</span>
                                    <span>{travelTimeShip}</span>
                                </div>
                                <button className="info-button" onClick={() => infoButton(ship)}>i</button>
                            </div>
                        )}

                        {/* Корабль сдан в аренду */}
                        {isRentedOut && (
                            <div className="ship-travel-info">
                                <div className="ship-travel-info-box">
                                    <span>{t('on-rent')}</span>
                                    <span>{rentTimeShip}</span>
                                </div>
                                <button className="info-button" onClick={() => infoButton(ship)}>i</button>
                            </div>
                        )} 
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {soonAlert && <SoonAlert soonAlert={soonAlert} setSoonAlert={setSoonAlert}/>}
            <Rented />
            {mintPage ? (<MintShipsPage setMintPage={setMintPage} updateShips={updateShips}/>) : null}

            {modalUpgrade && <UpgradeModal modalUpgrade={modalUpgrade} setModalUpgrade={setModalUpgrade}
                config={config} selectedShip={selectedShip} shipImages={shipImages} updateShips={updateShips}/>}

            {modalRent && <RentModal modalRent={modalRent} setModalRent={setModalRent} selectedShip={selectedShip}
                shipImages={shipImages} getShipCapacity={getShipCapacity} ships={ships} setShips={setShips}
                updateShips={updateShips} rentOutConfirm={rentOutConfirm}/>}
            
            {info ? <InfoShips info={info} setInfo={setInfo} currentShip={selectedShip} config={config} shipImages={shipImages}/> : null}

            <div className="ships-header">
                <button className="ships-back-button" onClick={() => setActiveSection('port')}>
                    <img src={backImage} alt="Back" />
                </button>
                <span className="title-ships">{t('title-ships')}</span>
                <button className="buy-ships-button" onClick={() => setMintPage(true)}>{t('buy')}</button>
            </div>

            {!dataLoaded ? (
                <div className="ship-loading-animation">
                    <svg className="spinner" strokeWidth="65px" height="20%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            ) : (
                <div className="ships-box">
                    {ships && ships.length > 0 ? (
                        ships.map(ship => (
                            <ShipBox key={ship.id} ship={ship} />
                        ))
                    ) : (
                        <>
                        <div className="ships-not">
                            <span>{t("ships-not1")}</span>
                            <span>{t("ships-not2")}</span>
                        </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default Ships;