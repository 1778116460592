
import React, { useEffect, useState } from 'react';
import './profile.scss';
import { changeWallet } from '../requests'
import LanguageButton from '../translate/button';
import MiniGame from './mini-game';
import TaskList from './task-list';
import Referal from './referal';
import WalletIcon from './wallet.png';
import TutorialIcon from '../tutorial/man.png';
import RatingIcon from './rating.png';
import ClanIcon from '../navigation/clans.png';

import { useTranslate } from '../translate/translate';
import { useTranslation } from 'react-i18next'
import { useTonAddress } from '@tonconnect/ui-react';
import { useTonConnectUI } from '@tonconnect/ui-react';

import VoyageEnd from '../voyage/voyage_end';
import Sold from '../market/sold';
import Rented from '../port/rent/rented';
import Rating from './rating';
import Warning from '../warnings';
import Clans from './clans';

import Ava1 from './ava/1.png';
import Ava2 from './ava/2.png';
import Ava3 from './ava/3.png';
import Ava4 from './ava/4.png';
import Ava5 from './ava/5.png';
import Ava6 from './ava/6.png';

const avatarImages = {
    1: Ava1,
    2: Ava2,
    3: Ava3,
    4: Ava4,
    5: Ava5,
    6: Ava6,
};

const ModalWallet = ({ isOpen, onClose, onDisconnect, WalletFormat, walletFr }) => {
    const t = useTranslate();
    if (!isOpen) return null;
  
    return (
        <div className="modal-wallet-overlay">
            <div className="modal-wallet-content">
                <span className="modal-wallet-title">{t("wallet")}</span>
                <span className="modal-wallet-info">
                    {t("wallet-info")}
                </span>
                <span className="modal-wallet-wallet">{WalletFormat(walletFr)}</span>
                <div className="modal-wallet-buttons">
                    <button onClick={onDisconnect}>{t("disconnect")}</button>
                    <button onClick={onClose}>{t("cancel2")}</button>
                </div>
            </div>
        </div>
    );
  };

const Profile = ({ setStep, setTutorial, setLVL, setXP, flotilias, setFlotilias }) => {
    const t = useTranslate();
    const { i18n } = useTranslation();
    const [tonConnectUI] = useTonConnectUI();
    const [isConnected, setIsConnected] = useState(false);
    const wallet = useTonAddress(false);
    const walletFr = useTonAddress(true);
    const [isModalWalletOpen, setIsModalWalletOpen] = useState(false);
    const [isTutorial, setIsTutorial] = useState(false);
    const [isRating, setIsRating] = useState(false);
    const [warning, setWarning] = useState(false);

    useEffect(() => {
    }, [i18n.language]);

    const WalletFormat = (Address) => {
        const formattedAddress = `${Address.slice(0, 4)}...${Address.slice(-4)}`;
        return formattedAddress;
    };  

    useEffect(() => {
        const checkWalletConnection = async () => {
          const isConnected = tonConnectUI.connected;
          if (isConnected) {
            setIsConnected(true); // Кошелек подключен
            const disconnectInfo = await changeWallet(window.tgID, wallet); // Присваивается window.wallet
            if (disconnectInfo) {
                await tonConnectUI.disconnect();
                setIsConnected(false);
                window.wallet = undefined;
                setWarning(true);
            }
          } else {
            setIsConnected(false); // Кошелек не подключен
          }
        };
    
        checkWalletConnection();
    }, [tonConnectUI.connected]);

    const handleConnect = async () => {
        if (isConnected) {
            setIsModalWalletOpen(true);
        } else {
            await tonConnectUI.openModal();
        }
    };

    const handleDisconnect = async () => {
        await tonConnectUI.disconnect();
        setIsConnected(false);
        setIsModalWalletOpen(false);
        window.wallet = undefined;
    };

    const startTutorialFromProfile = () => {
        setTutorial(true);
        setStep(3);
        setIsTutorial(false);
    };

    const avatar = window.avatar;
    const avatarImage = avatar === 1 ? Ava1 :
                        avatar === 2 ? Ava2 :
                        avatar === 3 ? Ava3 :
                        avatar === 4 ? Ava4 :
                        avatar === 5 ? Ava5 :
                        avatar === 6 ? Ava6 : Ava1;

    return (
        <>
        <ModalWallet
            isOpen={isModalWalletOpen}
            onClose={() => setIsModalWalletOpen(false)}
            onDisconnect={handleDisconnect}
            walletFr={walletFr}
            WalletFormat={WalletFormat}
        />

        <div className="home">
            {warning ? <Warning warning={warning} setWarning={setWarning}/> : null}
            <Sold />
            <Rented />
            <VoyageEnd onVoyagePage={false} setLVL={setLVL} setXP={setXP}/>

            {flotilias && <Clans flotilias={flotilias} setFlotilias={setFlotilias} />}
            <div id="clans-portal"></div>

            <div className='profile-buttons-box'>
                <div className='ref-tut-buttons'>
                    <Referal />
                    <button className='profile-tutorial-button' onClick={() => setIsTutorial(true)}>
                        <img src={TutorialIcon} alt="Tutorial" />
                    </button>
                    <button className='profile-flotilias-button' onClick={() => setFlotilias(true)}>
                        <img src={ClanIcon} alt="Flotilias" />
                    </button>
                </div>
                
                <div className='profile-box'>
                    <img src={avatarImage} alt="Avatar" />
                    <div className='name'>
                        <span>{window.firstname}</span> 
                        <span>{window.lastname ? window.lastname : ''}</span>
                    </div>
                </div>
                <div className='lang-wallet-buttons'>
                    <LanguageButton />
                    <button className='ton-button-profile' onClick={handleConnect}>
                        <img src={WalletIcon} alt="Wallet" />
                    </button>

                    <button className='rating-button' onClick={() => setIsRating(true)}>
                        <img src={RatingIcon} alt="Rating" />
                    </button>
                </div>
                
            </div>

            <MiniGame />
            <TaskList />

            {isTutorial ? (
                <div className='profile-tutorial-overlay'>
                    <div className='profile-tutorial-box'>
                        <div className='profile-tutorial-tell-box'>
                            <span>{t("prof-tutorial")}</span>
                            <button className='profile-tutorial-yes' onClick={() => startTutorialFromProfile()}>
                                {t("yes")}
                            </button>
                            <button className='profile-tutorial-no' onClick={() => setIsTutorial(false)}>
                                {t("no")}
                            </button>
                            <img className='profile-tutorial-man' src={TutorialIcon} alt="Tutorial" />
                        </div>
                    </div>
                </div>
            ) : null}

            {isRating ? (
                <Rating isRating={isRating} setIsRating={setIsRating}/>
            ) : null}

        </div>
        </>
    );
};

export default Profile;