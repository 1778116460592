import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './voyage_end.scss';
import { useTranslate } from '../translate/translate';
import { getMyVoyages, getShipById, endVoyage, getUserInfo, changeEventData, getTimeServer } from '../requests';

import config_ship from '../port/ships/ships_upgrade.json';
import config_store from '../port/store/config_store.json';
import { format } from '../format-number';
import DeductalModal from './deducted';
import LevelupModal from './levelup';

import XpImage from '../port/xp.png';
import capacityImage from '../port/ships/cont-icon.png'

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';
import Temp1Resource from './temp/temp1_resource.png'

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image,
    15: Temp1Resource
};

const containerImages = {
    'blue-cont': ContBlueImage,
    'red-cont': ContRedImage,
    'green-cont': ContGreenImage,
    'bronze-cont': ContBronzeImage,
    'silver-cont': ContSilverImage,
    'gold-cont': ContGoldImage,
};

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const getShipCapacity = (shipType, level) => {
    const shipConfig = config_ship.ships.find(ship => ship.type === shipType);
    if (shipConfig) {
        const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
        return levelConfig ? levelConfig.capacity : 'N/A';
    }
    return 'N/A';
};

const VoyageEnd = ({ onVoyagePage, setRefreshData, setLVL, setXP }) => {
    const t = useTranslate();

    const [showModals, setShowModals] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const intervalRef = useRef(null); // Интервал для обновления каждую секунду
    const [calculatedResourcesState, setCalculatedResourcesState] = useState({});
    const [loading, setLoading] = useState(false);
    const [deductedModal, setDeductalModal] = useState(null);
    const [levelupModal, setLevelupModal] = useState(null);

    useEffect(() => {
        if (window.tgID) {
            setDataLoaded(true);
        }
    }, [window.tgID]);

    useEffect(() => {
        const fetchData = async () => {
            if (dataLoaded) {
                const myVoyages_response = await getMyVoyages(window.tgID);
                
                const modalsToShow = await Promise.all(myVoyages_response.map(async voyage => {

                    const serverTimeResp = await getTimeServer();
                    const serverTime = new Date(serverTimeResp).getTime();

                    const timerDate = new Date(voyage.timer);
                    if (serverTime > timerDate) {
                        const shipInfo = await getShipById(voyage.ship);
                        await getUserInfo(window.tgID);
                        let calculatedResources = calculatedResourcesState[voyage.id];
                        if (!calculatedResources) {
                            const resources = voyage.resources;
                            calculatedResources = {};

                            for (const [resourceId, resourceData] of Object.entries(resources)) {
                                let amount;
                                if (resourceData.random !== undefined) {
                                    amount = resourceData.random;
                                } else {
                                    const min = resourceData.min;
                                    const max = resourceData.max;
                                    amount = (min + max) / 2;
                                }
                                calculatedResources[resourceId] = { amount };
                            }
                            setCalculatedResourcesState(prevState => ({
                                ...prevState,
                                [voyage.id]: calculatedResources
                            }));
                        }
                        
                        return { id: voyage.id, data: voyage, shipInfo, calculatedResources };
                    }
                    return null;
                }));

                setShowModals(modalsToShow.filter(modal => modal !== null));
            }
        };
    
        if (dataLoaded) {
            fetchData();
        }
    
        // Устанавливаем интервал только если onVoyagePage равно true
        if (onVoyagePage) {
            intervalRef.current = setInterval(fetchData, 5000);
        }
    
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [dataLoaded, onVoyagePage]);

    const adjustResources = (calculatedResources) => {
        const capacity = config_store.store.find(store => store.lvl === window.lvl_store).capacity[0].resources;
        return window.resources.map((resource, index) => {
            if (calculatedResources[index] !== undefined && calculatedResources[index] !== 0) {
                const total = resource + calculatedResources[index];
                return total > capacity[index] ? capacity[index] - resource : calculatedResources[index];
            }
            return 0;
        });
    };

    const calculateDeductedResources = (calculatedResources, adjustedResources) => {
        return calculatedResources.map((calculated, index) => {
            if (calculated !== 0) {
                return calculated - adjustedResources[index];
            }
            return 0;
        });
    };

    const VoyageEndFunc = (currentVoyage, modalId, Resources) => {
        setLoading(true);
        const calculatedResources = new Array(14).fill(0);
        for (const [resourceId, resourceData] of Object.entries(Resources)) {
            const id = parseInt(resourceId, 10) - 1; // Вычитаем 1, чтобы индексы начинались с 0
            calculatedResources[id] = resourceData.amount;
        }
    
        const adjustedResources = adjustResources(calculatedResources); // Прибавится
        const deductedResources = calculateDeductedResources(calculatedResources, adjustedResources); // Потеряно (из-за переполненного склада)
    
        if (deductedResources.every(resource => resource === 0)) {
            /* tgID, resources, conts, xp, idVoyage */
            endVoyage(window.tgID, calculatedResources, currentVoyage.id)
                .then(endVoyageResp => {
                    changeModals(modalId);
    
                    let prevLvl = Math.floor((-1 + Math.sqrt(1 + (8 * window.xp) / 10)) / 2) + 1;
                    let nowLvl = Math.floor((-1 + Math.sqrt(1 + (8 * endVoyageResp.xp) / 10)) / 2) + 1;
    
                    setXP(endVoyageResp.xp);
                    setLVL(endVoyageResp.lvl);
                    if (nowLvl > prevLvl) { 
                        setLVL(nowLvl);
                        setLevelupModal(nowLvl);
                    }
                })
                .catch(error => {
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDeductalModal({
                adjustedResources: adjustedResources,
                voyage: currentVoyage.id,
                modalId: modalId
            });
            setLoading(false);
        }
    };

    const changeModals = (modalId) => {
        setShowModals(prevShowModals => prevShowModals.filter(m => m.id !== modalId));
    
        setCalculatedResourcesState(prevState => {
            const newState = { ...prevState };
            delete newState[modalId];
            return newState;
        });
    
        if (onVoyagePage) {
            setRefreshData(prevRefreshData => prevRefreshData + 1);
        }
    };

    return (
        <>
            {levelupModal && <LevelupModal levelupModal={levelupModal} setLevelupModal={setLevelupModal} />}
            {deductedModal && <DeductalModal deductalModal={deductedModal} setDeductalModal={setDeductalModal}
                resourceImages={resourceImages} endVoyage={endVoyage} changeModals={changeModals}
                levelupModal={levelupModal} setLevelupModal={setLevelupModal} setLVL={setLVL} setXP={setXP}/>}
            {showModals.map(voyage => (
                ReactDOM.createPortal(
                    <div key={voyage.id} className="modal-end-voyage-overlay">
                        <div className="modal-end-voyage">
                            
                            <span className="modal-end-voyage-title">{t('end-voyage-title')}</span>
                
                            <div className="modal-end-voyage-ships-conts">
                                <div className="modal-end-voyage-ships">
                                    <img src={shipImages[voyage.shipInfo.type]} alt="Selected Ship" />
                                    <div className="name">{voyage.shipInfo.name}</div>
                                    <div className="lvl">{t('lvl')} {voyage.shipInfo.lvl}</div>
                                    <div className="capacity">
                                        {getShipCapacity(voyage.shipInfo.type, voyage.shipInfo.lvl)}
                                        <img src={capacityImage} alt='Capacity'/>
                                    </div>
                                </div>
                                <div className="modal-end-voyage-conts">
                                    {voyage.data.conts.map((count, index) => {
                                        if (count > 0) {
                                            const type = Object.keys(containerImages)[index];
                                            return (
                                                <div key={type} className="info-cont-item">
                                                    <img src={containerImages[type]} alt={type} />
                                                    <div className="info-cont-item-amount">{count}</div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>

                            <div className="modal-end-voyage-earnings">
                                {voyage.data && Object.entries(voyage.calculatedResources).map(([resourceId, resourceData]) => (
                                    <div key={resourceId} className="info-country-resource-item">
                                        <div className="info-country-resource-img-box">
                                            <img src={resourceImages[resourceId]} alt={`Resource ${resourceId}`} />
                                        </div>
                                        {resourceId == 15 ? (
                                            <div className="info-country-resource-amount">
                                                <span>{resourceData.amount}</span>
                                            </div>
                                        ) : (
                                            <div className="info-country-resource-amount">
                                                <span>{format(resourceData.amount)}</span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="info-country-resource-item"> {/* xp в конец */}
                                    <div className="info-country-resource-img-box">
                                        <img src={XpImage} alt='XP' />
                                    </div>
                                    <div className="info-country-resource-amount">
                                        <span>{voyage.data.xp}</span>
                                    </div>
                                </div>
                            </div>

                            <button className={`modal-end-voyage-ok ${(loading) ? 'disabled' : ''}`} onClick={() => {
                                VoyageEndFunc(voyage.data, voyage.id, voyage.calculatedResources);
                            }}>
                                {loading ? (
                                    <div className="loading-animation">
                                        <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </div>
                                ) : 
                                    <span>{t('ok')}</span>
                                }
                            </button>

                        </div>
                    </div>,
                    document.body
                )
            ))}
        </>
    );
};

export default VoyageEnd;