import React, { useEffect, useState } from 'react';
import './tutorial.scss';
import { useTranslation } from 'react-i18next';
import { useTranslate } from '../translate/translate';
import { changeLanguageRequest, avatarChoose, tutorialEnd } from '../requests';

import manImage from './man.png';
import LangIconEn from '../translate/en.png';
import LangIconRu from '../translate/ru.png';

import shipImage from '../port/ship.png';
import contImage from '../port/cont.png';
import storeImage from '../port/store.png';
import rigImage from './oil-rig.png';
import voyageImage from '../navigation/trip.png';
import componentImage from '../components/3.png';
import marketImage from '../navigation/market.png';
import finalImage from './final.png';
import voyagesImage from './voyages.jpg';
import warehouseImage from './warehouse.jpg';
import warehouseEnImage from './warehouse_en.jpg';
import rigsImage from './rigs.jpg';
import rigsEnImage from './rigs_en.jpg';
import componentsImage from './components.jpg';
import componentsEnImage from './components.jpg';
import marketsImage from './market.jpg';
import marketsEnImage from './market_en.jpg';
import logoImage from '../logo.png';

import Ava1 from '../profile/ava/1.png';
import Ava2 from '../profile/ava/2.png';
import Ava3 from '../profile/ava/3.png';
import Ava4 from '../profile/ava/4.png';
import Ava5 from '../profile/ava/5.png';
import Ava6 from '../profile/ava/6.png';
import AvaSelectIcon from '../profile/ava/choose.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const navImg = {
    1: { img: LangIconEn, name: 'Language' },
    2: { img: manImage, name: 'Avatar' },
    3: { img: shipImage, name: 'Ships' },
    4: { img: contImage, name: 'Containers' },
    5: { img: voyageImage, name: 'Voyages' }, 
    6: { img: storeImage, name: 'Warehouse' }, 
    7: { img: rigImage, name: 'Oil rigs' },
    8: { img: componentImage, name: 'Components' },
    9: { img: marketImage, name: 'Market' },
    10: { img: finalImage, name: 'Final' }
}

const shipImages = {
    1: {img: PioneerImage, name: 'Pioneer'},
    2: {img: ExplorerImage, name: 'Explorer'},
    3: {img: VoyagerImage, name: 'Voyager'},
    4: {img: OdysseyImage, name: 'Odyssey'},
    5: {img: TitanImage, name: 'Titan'}
}

const contImages = {
    1: {img: ContBlueImage},
    2: {img: ContRedImage},
    3: {img: ContGreenImage},
    4: {img: ContBronzeImage},
    5: {img: ContSilverImage},
    6: {img: ContGoldImage}
}

function Tutorial({ tutorial, setTutorial, step, setStep }) {
    const { i18n } = useTranslation();
    const t = useTranslate();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [clickedLanguage, setClickedLanguage] = useState(null);
    const [langChoosen, setLangChoosen] = useState(false);

    const [nextStepAvailable, setNextStepAvailable] = useState(false);
    const [animation, setAnimation] = useState(true);
    const [currentShipIndex, setCurrentShipIndex] = useState(1);
    const [animationShip, setAnimationShip] = useState(true);
    const [avaChoose, setAvaChoose] = useState(0);
    const [currentContIndex, setCurrentContIndex] = useState(1);
    const [animationCont, setAnimationCont] = useState(true);
    const [tutorialRepeat, setTutorialRepeat] = useState(false);
    const [showPyro, setShowPyro] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimation(false);
            setNextStepAvailable(false);
            setTimeout(() => {
                if (step != 1 && step != 2) {
                    setNextStepAvailable(true);
                }
            }, 300);
        }, 300);

        return () => clearTimeout(timer);
    }, [step]);

    useEffect(() => {
        if (avaChoose) {
            setNextStepAvailable(true);
        }
    }, [avaChoose]);

    useEffect(() => {
        if (window.default_ship > 0) {
            setStep(3);
            setTutorialRepeat(true);
            setLangChoosen(true);
        }
    }, []);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
        setClickedLanguage(language);
        setLangChoosen(true);
        changeLanguageRequest(window.tgID).then(() => {
            setNextStepAvailable(true);
            window.lang = currentLanguage;
        })
    };

    const nextStep = async () => {
        if (step === 2) {
            await avatarChoose(window.tgID, avaChoose);
        }
        setNextStepAvailable(false);
        setStep(prev => prev+1);
        setAnimation(true);
    };

    const prevStep = async () => {
        if (tutorialRepeat && step === 3) {
            return
        } else {
            setNextStepAvailable(false);
            setStep(prev => prev-1);
            setAnimation(true);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimationShip(true);
          setTimeout(() => {
            setCurrentShipIndex((prevIndex) => (prevIndex % Object.keys(shipImages).length) + 1);
            setAnimationShip(false);
          }, 1000);
        }, 1500);
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimationCont(true);
          setTimeout(() => {
            setCurrentContIndex((prevIndex) => (prevIndex % Object.keys(contImages).length) + 1);
            setAnimationCont(false);
          }, 1000);
        }, 1500);
    
        return () => clearInterval(interval);
    }, []);

    const finalStep = async () => {
        if (!window.tutorial) {
            await tutorialEnd(window.tgID);
        }
        setShowPyro(true);
        setTimeout(() => {
            setShowPyro(false);
            setTutorial(false);
        }, 1500);
    };

    return (
        <div className='tutorial-overlay'>
            <div className='tutorial-content-box'>
                <div className={`tutorial-content ${animation ? '' : 'visible'}`}>
                    {step === 1 ? (
                        <>
                        <div className='lang-buttons'>
                            <button 
                                className={`tutorial-lang ${clickedLanguage === 'ru' ? 'clicked' : 'no-clicked'}`} 
                                onClick={() => changeLanguage('ru')}
                            >
                                <img src={LangIconRu} alt="Russian" />
                            </button>
                            <button 
                                className={`tutorial-lang ${clickedLanguage === 'en' ? 'clicked' : 'no-clicked'}`} 
                                onClick={() => changeLanguage('en')}
                            >
                                <img src={LangIconEn} alt="English" />
                            </button>
                        </div>
                            
                        </>
                    ) : step === 2 ? (
                        <>
                            <span className='text-bold'>{t('tutorial7')}</span>
                            <div className='ava-box'>
                                {[Ava1, Ava2, Ava3, Ava4, Ava5, Ava6].map((ava, index) => (
                                    <button key={index} className={`ava-button ${avaChoose === index + 1 ? 'selected' : ''}`} onClick={() => setAvaChoose(index + 1)}>
                                        <img src={ava} alt={`Avatar ${index + 1}`} />
                                        {avaChoose === index + 1 && (
                                            <img className='choose-icon' src={AvaSelectIcon} alt='Selected'/>
                                        )}
                                    </button>
                                ))}
                            </div>
                        </>
                    ) : step === 3 ? (
                        <>
                            <div className='ship-box'>
                                <img
                                    src={shipImages[currentShipIndex].img}
                                    alt={shipImages[currentShipIndex].name}
                                    className={`ship-image ${animationShip ? '' : 'visible'}`}
                                />
                                <div className={`name ${animationShip ? '' : 'visible'}`}>{shipImages[currentShipIndex].name}</div>
                            </div>
                            <span className='text-bold'>{t('tutor1')}</span>
                            <span className='text-bold'>{t('tutor2')}</span>
                            <span className='text-bold'>{t('tutor3')}</span>
                        </>
                    ) : step === 4 ? (
                        <>
                            <div className='cont-box'>
                                <img
                                    src={contImages[currentContIndex].img}
                                    alt='Container'
                                    className={`cont-image ${animationCont ? '' : 'visible'}`}
                                />
                            </div>
                            <span className='text-bold'>{t('tutor4')}</span>
                            <span className='text-bold'>{t('tutor5')}</span>
                            <span className='text-bold'>{t('tutor6')}</span>
                        </>
                    ) : step === 5 ? (
                        <>
                            <img className='voyages-img' src={voyagesImage} alt="Voyages" />
                            <span className='text-bold'>{t('tutor7')}</span>
                            <div className='resources-box'>
                                {Object.keys(resourceImages).map((key) => (
                                    <img key={key} src={resourceImages[key]} alt={`Resource ${key}`} />
                                ))}
                            </div>
                            <span className='text-bold'>{t('tutor8')}</span>
                        </>
                    ) : step === 6 ? (
                        <>
                            <img className='warehouse-img' src={window.lang == 'en' ? warehouseEnImage : warehouseImage} alt="Warehouse" />
                            <span className='text-bold'>{t('tutor9')}</span>
                            <span className='text-bold'>{t('tutor10')}</span>
                        </>
                    ) : step === 7 ? (
                        <>
                            <img className='rig-img' src={window.lang == 'en' ? rigsEnImage : rigsImage} alt="Oil rig" />
                            <span className='text-bold'>{t('tutor11')}</span>
                            <span className='text-bold'>{t('tutor12')}</span>
                        </>
                    ) : step === 8 ? (
                        <>
                            <img className='components-img' src={window.lang == 'en' ? componentsEnImage : componentsImage} alt="Workshop" />
                            <span className='text-bold'>{t('tutor13')}</span>
                            <span className='text-bold'>{t('tutor14')}</span>
                        </>
                    ) : step === 9 ? (
                        <>
                            <img className='market-img' src={window.lang == 'en' ? marketsEnImage : marketsImage} alt="Market" />
                            <span className='text-bold'>{t('tutor15')}</span>
                            <span className='text-bold'>{t('tutor16')}</span>
                        </>
                    ) : step === 10 ? (
                        <>
                            <img className='logo-img' src={logoImage} alt="Container Rush" />
                            <span className='text-bold'>{t('tutor17')}</span>
                            <span className='text-bold'>{t("tutorial45")}</span>
                            <span className='text-bold'>{t("tutorial46")}</span>
                            <span className='text-bold'>{t("tutorial47")}</span>
                            <button className='final-button' onClick={() => finalStep()}>
                                {t('final-tutorial')}
                            </button>
                        </>
                    ) : null}

                    {showPyro && (
                        <div className="pyro">
                            <div className="before"></div>
                            <div className="after"></div>
                        </div>
                    )}
                </div> 
            </div>

            <div className='tutorial-nav'>
                <hr className='line'/>

                {step != 1 &&
                    <button className='left' onClick={() => prevStep()}>
                        <img src={navImg[step-1].img} alt="Step" />
                        <span>
                            {t(navImg[step-1].name)}
                        </span>
                    </button>
                }

                <button className='center'>
                    <img src={navImg[step].img} alt="Step" />
                    <span>
                        {t(navImg[step].name)}
                    </span>
                </button>

                {nextStepAvailable && step != 10 &&
                    <button className={`right ${animation ? '' : 'visible'}`} onClick={() => nextStep()}>
                        <img src={navImg[step+1].img} alt="Step" />
                        <span>
                            {t(navImg[step+1].name)}
                        </span>
                    </button>
                }

            </div>
            
        </div>
    );
}

export default Tutorial;
