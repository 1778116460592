
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import './voyage.scss';
import config from './country.json';
import config_ship from '../port/ships/ships_upgrade.json';
import config_conts from '../port/cont/cont_upgrade.json';
import config_store from '../port/store/config_store.json';
import { format } from '../format-number';
import ReactDOM from 'react-dom';
import { useTranslate } from '../translate/translate';

import ContentLoader from "react-content-loader"
import Skeleton from '../skeleton';

import { getTimeServer, getUserInfo, getShipsWithRented, getContainers, toVoyage, getMyVoyages, checkSubscribe } from '../requests'
import VoyageEnd from './voyage_end';
import Alert from '../alerts';
import RankImage from './temp/rank.png';
import ModalList from './voyages_list';
import FullStoreModal from './full_store';

import MapImage from './map3.jpg';
import MarkerCloseImage from './marker_close.png';
import MarkerOpenImage from './marker_open.png';
import LockImage from './lock.png';
import AnchorImage from './anchor.png';
import HelmImage from './helm.png';
import TimerImage from './time.png';
import XpImage from '../port/xp.png';
import capacityImage from '../port/ships/cont-icon.png'
import BackImage from '../port/back.png';

import Temp1Marker from './temp/temp1_marker.png';
import Temp2Icon from './temp/hmstr_icon.png';
import Temp2Anounce from './temp/temp2_anounce';

import HodlIcon from './temp/hodl_icon.png';
import HodlImage from './temp/hodl_image.jpg';
import EventQuestModal from './temp/event_quest_modal';
import EventInfo from './temp/event_info';
import EventSubscribe from './temp/event_subscribe';

import EuropeImage from './country/Europe.jpeg';
import AmericaImage from './country/America.jpeg';
import AfricaImage from './country/Africa.jpeg';
import BrazilImage from './country/Brazil.jpeg';
import SaudiImage from './country/Saudi.jpeg';
import ChinaImage from './country/China.jpeg';
import RussiaImage from './country/Russia.jpeg';
import MadagascarImage from './country/Madagascar.jpeg';
import IndiaImage from './country/India.jpeg';
import GreenImage from './country/Greenland.jpeg';
import AustraliaImage from './country/Australia.jpeg';
import AntarcticaImage from './country/Antarctica.jpeg';

import Resource1Image from '../resources/1.png';
import Resource2Image from '../resources/2.png';
import Resource3Image from '../resources/3.png';
import Resource4Image from '../resources/4.png';
import Resource5Image from '../resources/5.png';
import Resource6Image from '../resources/6.png';
import Resource7Image from '../resources/7.png';
import Resource8Image from '../resources/8.png';
import Resource9Image from '../resources/9.png';
import Resource10Image from '../resources/10.png';
import Resource11Image from '../resources/11.png';
import Resource12Image from '../resources/12.png';
import Resource13Image from '../resources/13.png';
import Resource14Image from '../resources/14.png';

import PioneerImage from '../port/ships/pioneer.png';
import ExplorerImage from '../port/ships/explorer.png';
import VoyagerImage from '../port/ships/voyager.png';
import OdysseyImage from '../port/ships/odyssey.png';
import TitanImage from '../port/ships/titan.png';

import ContBlueImage from '../port/cont/blue.png';
import ContRedImage from '../port/cont/red.png';
import ContGreenImage from '../port/cont/green.png';
import ContBronzeImage from '../port/cont/bronze.png';
import ContSilverImage from '../port/cont/silver.png';
import ContGoldImage from '../port/cont/gold.png';

const containerImages = {
    'blue-cont': ContBlueImage,
    'red-cont': ContRedImage,
    'green-cont': ContGreenImage,
    'bronze-cont': ContBronzeImage,
    'silver-cont': ContSilverImage,
    'gold-cont': ContGoldImage,
};

const resourceImages = {
    1: Resource1Image,
    2: Resource2Image,
    3: Resource3Image,
    4: Resource4Image,
    5: Resource5Image,
    6: Resource6Image,
    7: Resource7Image,
    8: Resource8Image,
    9: Resource9Image,
    10: Resource10Image,
    11: Resource11Image,
    12: Resource12Image,
    13: Resource13Image,
    14: Resource14Image
};

const countryImages = {
    "Europe": EuropeImage,
    "North America": AmericaImage,
    "Africa": AfricaImage,
    "Brazil": BrazilImage,
    "Saudi Arabia": SaudiImage,
    "China": ChinaImage,
    "Russia": RussiaImage,
    "Madagascar": MadagascarImage,
    "India": IndiaImage,
    "Greenland": GreenImage,
    "Australia": AustraliaImage,
    "Antarctica": AntarcticaImage,
    "HODL, FUCK!": HodlImage
};

const shipImages = {
    'Pioneer': PioneerImage,
    'Explorer': ExplorerImage,
    'Voyager': VoyagerImage,
    'Odyssey': OdysseyImage,
    'Titan': TitanImage
};

const getShipCapacity = (shipType, level) => {
    const shipConfig = config_ship.ships.find(ship => ship.type === shipType);
    if (shipConfig) {
        const levelConfig = shipConfig.levels.find(lvl => lvl.level === level);
        return levelConfig ? levelConfig.capacity : 'N/A';
    }
    return 'N/A';
};

const getShipSpeed = (shipType, level) => {
    const speedConfig = config_ship.ships.find(ship => ship.type === shipType);
    if (speedConfig) {
        const levelConfig = speedConfig.levels.find(lvl => lvl.level === level);
        return levelConfig.speed;
    }
};

const calculateResourceAmounts = (earning, selectedContainers) => {
    let minAmount = 0;
    let maxAmount = 0;

    if (Object.values(selectedContainers).every(count => count === 0)) {
        minAmount = parseFloat(earning['min-amount'].toFixed(2));
        maxAmount = parseFloat(earning['max-amount'].toFixed(2));
    } else {
        Object.keys(selectedContainers).forEach((containerType, index) => {
            const containerConfig = config_conts.containers.find(cont => cont.id === containerType);
            if (containerConfig) {
                const levelConfig = containerConfig.levels.find(lvl => lvl.level === window.lvl_conts[index]);
                if (levelConfig) {
                    const bonusRes = levelConfig.bonus_res;
                    const selectedCount = selectedContainers[containerType];
                    minAmount += parseFloat(((earning['min-amount'] + (earning['min-amount'] * (bonusRes / 100))) * selectedCount).toFixed(2));
                    maxAmount += parseFloat(((earning['max-amount'] + (earning['max-amount'] * (bonusRes / 100))) * selectedCount).toFixed(2));
                }
            }
        });
    }

    return { minAmount, maxAmount };
};

const calculateResourceAmountsInt = (earning, selectedContainers) => {
    let minAmountInt = 0;
    let maxAmountInt = 0;

    if (Object.values(selectedContainers).every(count => count === 0)) {
        minAmountInt = Math.round(parseInt(earning['min-amount']));
        maxAmountInt = Math.round(parseInt(earning['max-amount']));
    } else {
        Object.keys(selectedContainers).forEach((containerType, index) => {
            const containerConfig = config_conts.containers.find(cont => cont.id === containerType);
            if (containerConfig) {
                const levelConfig = containerConfig.levels.find(lvl => lvl.level === window.lvl_conts[index]);
                if (levelConfig) {
                    const bonusRes = levelConfig.bonus_res;
                    const selectedCount = selectedContainers[containerType];
                    minAmountInt += Math.round(((earning['min-amount'] + (earning['min-amount'] * (bonusRes / 100))) * selectedCount));
                    maxAmountInt += Math.round(((earning['max-amount'] + (earning['max-amount'] * (bonusRes / 100))) * selectedCount));
                }
            }
        });
    }

    return { minAmountInt, maxAmountInt };
};

const ModalInfo = ({ onClose, selectedCountry, currentSpeed, ships, voyage, tempTerm, termType }) => {
    const t = useTranslate();
    const [serverTime, setServerTime] = useState(null);
    const serverTimeRef = useRef(null);
    const [timeLeft, setTimeLeft] = useState(voyage ? new Date(voyage.timer).getTime() - (serverTime || Date.now()) : 0);

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs;
    };

    useEffect(() => {
        let tickIntervalId;

        fetchServerTime();

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(tickIntervalId);
        };
    }, []);

    const formatTimeLeft = (timeLeft) => {
        const totalSeconds = Math.floor(timeLeft / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    useEffect(() => {
        if (voyage) {
            const interval = setInterval(() => {
                if (serverTimeRef.current) {
                    setTimeLeft(new Date(voyage.timer).getTime() - serverTimeRef.current);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [voyage]);

    const voyageShip = voyage ? ships.find(ship => ship.id === voyage.ship) : null;

    if (!selectedCountry || !ships || !voyage) {
        return ReactDOM.createPortal(
            <div className="country-modal-info-overlay">
                <div className="loading-animation">
                    <svg className="spinner" strokeWidth="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
            </div>,
            document.body
        );
    }

    return ReactDOM.createPortal(
        <div className="country-modal-info-overlay">
            <div className="country-modal-info-box">
                <span className="country-modal-info-title">{t('sended')}</span>
                <button className="close-button" onClick={onClose}>{t('ok')}</button>
                
                <div className="info-country-box">
                    <img className="info-country-img" src={countryImages[selectedCountry.name]} alt={selectedCountry.name} />
                    <div className="info-country-name">{t(selectedCountry.name)}</div>
                    <div className={`info-country-timer ${voyage ? 'active-voyage' : 'no-voyage'}`}>
                        <img src={TimerImage} alt="Timer" />
                        <span>{(voyage && serverTime) ? formatTimeLeft(timeLeft) : `${currentSpeed === 'N/A' ? selectedCountry.timer : Math.round(selectedCountry.timer * (1 - currentSpeed / 100))}h`}</span>
                    </div>
                    <div className="info-country-xp">
                        <img src={XpImage} alt="Xp" />
                        <span>{selectedCountry.xp}</span>
                    </div>
                    {selectedCountry.name == 'HODL, FUCK!' ? (
                        <>
                            {termType === 'end' && <div className="country-temp-term">{tempTerm}</div>}
                            <div className="event-points">
                                <span>{t('points')}: {window.event_points[0] ? window.event_points[0] : 0}</span>
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="info-ship">
                    <div className="info-ship-item">
                        {voyageShip && (
                            <>
                                <img src={shipImages[voyageShip.type]} alt="Selected Ship" />
                                <div className="info-ship-item-name">{voyageShip.name}</div>
                                <div className="info-ship-item-lvl">{t('lvl')} {voyageShip.lvl}</div>
                                <div className="info-ship-item-capacity">
                                    {getShipCapacity(voyageShip.type, voyageShip.lvl)}
                                    <img src={capacityImage} alt='Capacity'/>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="info-conts">
                        {voyage && voyage.conts.map((count, index) => {
                            if (count > 0) {
                                const type = Object.keys(containerImages)[index];
                                return (
                                    <div key={type} className="info-cont-item">
                                        <img src={containerImages[type]} alt={type} />
                                        <div className="info-cont-item-amount">{count}</div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="info-country-resources">
                    {voyage && Object.entries(voyage.resources).map(([resourceId, resourceData]) => (
                        <div key={resourceId} className="info-country-resource-item">
                            <div className="info-country-resource-img-box">
                                <img src={resourceImages[resourceId]} alt={`Resource ${resourceId}`} />
                            </div>
                            <div className="info-country-resource-amount">
                                <span>{format(resourceData.min)}–{format(resourceData.max)}</span>
                            </div>
                        </div>
                    ))}
                </div>    
            </div>
        </div>,
        document.body
    );
};

const Voyage = ({ setLVL, setXP, setNewEvent }) => {
    const t = useTranslate();
    const [serverTime, setServerTime] = useState(null);
    const serverTimeRef = useRef(null);
    const [refreshData, setRefreshData] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [ships, setShips] = useState([]);
    const [selectedShipIndex, setSelectedShipIndex] = useState(0);
    const [selectedContainers, setSelectedContainers] = useState({
        'blue-cont': 0,
        'red-cont': 0,
        'green-cont': 0,
        'bronze-cont': 0,
        'silver-cont': 0,
        'gold-cont': 0,
    });
    const [isSending, setIsSending] = useState(false);
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [myVoyages, setMyVoyages] = useState([]);
    const [timers, setTimers] = useState(false);
    const [activeVoyage, setActiveVoyage] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(null);
    const [buttonVoyages, setButtonVoyages] = useState(false);
    const [listSended, setListSended] = useState([]);
    const [modalListVoyages, setModalListVoyages] = useState(false);
    const mapRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [alert, setAlert] = useState(null);
    const [sendData, setSendData] = useState(null);
    const [fullStore, setFullStore] = useState(false);

    ///////////////////////////////////////////////////
    const [tempTerm, setTempTerm] = useState(null);
    const [termType, setTermType] = useState(null);
    const [eventQuestModal, setEventQuestModal] = useState(null);
    const [eventInfo, setEventInfo] = useState(false);
    const [eventSubscribe, setEventSubscribe] = useState(null);
    ///////////////////////////////////////////////////

    // Функция для получения времени с сервера
    const fetchServerTime = async () => {
        const time = await getTimeServer(); // Запрос на сервер
        const serverTimeMs = new Date(time).getTime();
        setServerTime(serverTimeMs);
        serverTimeRef.current = serverTimeMs; // Сохраняем в ref для обновлений каждую секунду
    };

    useEffect(() => {
        let syncIntervalId;
        let tickIntervalId;

        fetchServerTime();

        syncIntervalId = setInterval(fetchServerTime, 5000);

        tickIntervalId = setInterval(() => {
            if (serverTimeRef.current) {
                serverTimeRef.current += 1000;
                setServerTime(serverTimeRef.current);
            }
        }, 1000);

        return () => {
            clearInterval(syncIntervalId);
            clearInterval(tickIntervalId);
        };
    }, []);

    const totalSelectedContainers = useMemo(() => 
        Object.values(selectedContainers).reduce((acc, count) => acc + count, 0), 
        [selectedContainers]
    );

    const updateData = async () => {
        setDataLoaded(false);
        if (window.wallet) {
            await getUserInfo(window.tgID);
            await getContainers(window.tgID, window.wallet, 'voyage');
            const responseShips = await getShipsWithRented(window.tgID, window.wallet);
            setShips(responseShips);
        } else {
            await getUserInfo(window.tgID);
            await getContainers(window.tgID, null, 'voyage');
            const responseShips = await getShipsWithRented(window.tgID, null);
            setShips(responseShips);
        }
        setDataLoaded(true);
    };

    useEffect(() => {
        updateData();
        updateDataVoyages();
    }, [refreshData]);

    const selectCountry = async (index) => {
        const country = config.counties[index];
        const voyage = myVoyages.find(v => v.country === country.name);

        if (voyage) {
            const filteredVoyages = myVoyages.filter(v => v.country === country.name);
            if (filteredVoyages.length > 0) { setListSended(filteredVoyages) }

            // Проверка на наличие свободного корабля
            if (availableShips.length > 0) {
                // В любом случае отобразить кнопку с количеством
                updateData();
                setSelectedCountry(country);
                setShowModal(true);
                setButtonVoyages(true);
            } else {
                // Если отправлен 1 рейс то показать info
                if (filteredVoyages.length == 1) {
                    setActiveVoyage(voyage);
                    setSelectedCountry(country);
                    setShowModalInfo(true);
                    setButtonVoyages(true);
                } else if (filteredVoyages.length > 1) {
                    // Если несколько то показать модалку со списком
                    setSelectedCountry(country);
                    setModalListVoyages(true);
                }
            }
        } else {
            setListSended([]);
            updateData();
            setSelectedCountry(country);
            setShowModal(true);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedContainers({
            'blue-cont': 0,
            'red-cont': 0,
            'green-cont': 0,
            'bronze-cont': 0,
            'silver-cont': 0,
            'gold-cont': 0,
        });
        setButtonVoyages(false);
        setModalListVoyages(false);
        setListSended([]);
    };

    const updateDataVoyages = () => {
        getMyVoyages(window.tgID)
            .then(responseMyVoyages => {
                setMyVoyages(responseMyVoyages);
                setDataLoaded(true);
            })
            .catch(error => {
                console.error('Error fetching ships:', error);
            });
    };

    const closeModalInfo = () => {
        setShowModalInfo(false);
        setSelectedContainers({
            'blue-cont': 0,
            'red-cont': 0,
            'green-cont': 0,
            'bronze-cont': 0,
            'silver-cont': 0,
            'gold-cont': 0,
        });
        updateDataVoyages();
        if (listSended.length == 0) {
            setButtonVoyages(false);
            setModalListVoyages(false);
            setListSended([]);
        }
    };

    useEffect(() => {
        // Инициализация таймеров при первоначальном загрузке данных
        const initialTimers = {};
        const countryTimers = {};

        if (serverTime) {
            myVoyages.forEach(voyage => {
                const endTime = new Date(voyage.timer).getTime();
                const timeLeft = endTime - serverTime;
    
                if (timeLeft > 0) {
                    if (!countryTimers[voyage.country] || timeLeft < countryTimers[voyage.country]) {
                        countryTimers[voyage.country] = timeLeft;
                    }
                }
            });
    
            Object.keys(countryTimers).forEach(country => {
                initialTimers[country] = countryTimers[country];
            })
    
            setTimers(initialTimers);
    
            // Установка интервала для обновления таймеров каждую секунду
            const interval = setInterval(() => {
                setTimers(prevTimers => {
                    const newTimers = {};
                    let shouldUpdate = false;
                    Object.keys(prevTimers).forEach(country => {
                        const timeLeft = prevTimers[country] - 1000;
                        if (timeLeft > 0) {
                            newTimers[country] = timeLeft;
                        } else {
                            shouldUpdate = true;
                        }
                    });
                    if (shouldUpdate) {
                        // Обновление данных только если есть истекшие таймеры
                        updateDataVoyages();
                    }
                    return newTimers;
                });
            }, 1000);
    
            // Синхронизация с сервером каждые 5 минут
            const syncInterval = setInterval(() => {
                updateDataVoyages();
            }, 5 * 60 * 1000);
    
            return () => {
                clearInterval(interval);
                clearInterval(syncInterval);
            };
        }
    }, [myVoyages, serverTime]);

    const positions = [
        { left: 50, top: 30 },  // Европа
        { left: 29, top: 35 },   // Америка
        { left: 46, top: 45 },   // Африка
        { left: 37, top: 56 },   // Бразилия
        { left: 61, top: 42 },  // Саудовская Аравия
        { left: 78, top: 38 },  // Китай
        { left: 89, top: 22 },  // Россия
        { left: 61, top: 59 },  // Мадагаскар
        { left: 69.5, top: 44 },  // Индия
        { left: 38, top: 18 },   // Гренландия
        { left: 79.5, top: 62 },  // Австралия
        { left: 68, top: 83 },   // Антарктида
        { left: 46, top: 63 },   // Temp 2
        //{ left: 58, top: 17 },   // HODL, FUCK!
    ];

    const [markerPositionsLoaded, setMarkerPositionsLoaded] = useState(false);
    const mapImgRef = useRef(null);
    const [markerPositions, setMarkerPositions] = useState([]);
    
    useEffect(() => {
        // Обновление позиций маркеров при изменении размеров окна
        const updateMarkerPositions = () => {
            const mapElement = mapImgRef.current;
            if (!mapElement) return;

            const mapWidth = mapElement.offsetWidth;
            const mapHeight = mapElement.offsetHeight;

            const updatedPositions = positions.map((pos) => ({
                left: `${(mapWidth / 100) * pos.left}px`,  // Преобразование в проценты
                top: `${(mapHeight / 100) * pos.top}px`    // Преобразование в проценты
            }));

            setMarkerPositions(updatedPositions);
            setMarkerPositionsLoaded(true);
        };

        const timeoutId = setTimeout(() => {
            updateMarkerPositions();
        }, 150); // Задержка в 150 миллисекунд

        window.addEventListener('resize', updateMarkerPositions);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', updateMarkerPositions);
        };
    }, [markerPositionsLoaded]);

    useEffect(() => {
        const map = mapRef.current;

        const handleMouseDown = (e) => {
            setIsDragging(true);
            setStartX(e.pageX - map.offsetLeft);
            setScrollLeft(map.scrollLeft);
            map.style.cursor = 'grabbing';
        };

        const handleMouseLeave = () => {
            setIsDragging(false);
            map.style.cursor = 'grab';
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            map.style.cursor = 'grab';
        };

        const handleMouseMove = (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - map.offsetLeft;
            const walk = (x - startX) * 1.25; // scroll-fast
            map.scrollLeft = scrollLeft - walk;
        };

        map.addEventListener('mousedown', handleMouseDown);
        map.addEventListener('mouseleave', handleMouseLeave);
        map.addEventListener('mouseup', handleMouseUp);
        map.addEventListener('mousemove', handleMouseMove);

        return () => {
            map.removeEventListener('mousedown', handleMouseDown);
            map.removeEventListener('mouseleave', handleMouseLeave);
            map.removeEventListener('mouseup', handleMouseUp);
            map.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, startX, scrollLeft]);

    const countries = markerPositionsLoaded && markerPositions.map((position, index) => {
        const country = config.counties[index];
        const isUnlocked = window.lvl >= country['min-lvl'];
        const voyage = myVoyages.find(v => v.country === country.name);
        const markerImage = voyage ? HelmImage : (isUnlocked ? AnchorImage : LockImage);
        const timeLeft = timers[country.name];

        const formatTimeLeft = (timeLeft) => {
            const totalSeconds = Math.floor(timeLeft / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
        
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        };

        const voyageCount = myVoyages.reduce((count, v) => v.country === country.name ? count + 1 : count, 0);

        return (
            <button key={index} className="country"
                onClick={() => selectCountry(index)} 
                style={{
                    position: 'absolute',
                    left: position.left,
                    top: position.top,
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {country.name == 'Hamster rescue' || country.name == 'HODL, FUCK!' ? (
                        <>
                            <img className="country-marker" src={isUnlocked ? Temp1Marker : MarkerCloseImage} alt="" />

                            {voyage ? (
                                <img className={`country-marker-img ${voyage ? 'rotating' : ''}`} src={markerImage} alt="" />
                            ) : (
                                <img className="country-marker-img" src={country.name == 'Hamster rescue' ? Temp2Icon : country.name == 'HODL, FUCK!' ? HodlIcon : null} alt="" />
                            )}

                            <div className="wave"></div>
                        </> 
                    ) : (
                        <>
                            <img className="country-marker" src={isUnlocked ? MarkerOpenImage : MarkerCloseImage} alt="" />
                            <img className={`country-marker-img ${voyage ? 'rotating' : ''}`} src={markerImage} alt="" />
                        </>
                    )  
                } 
                {timers && timeLeft && (
                    <div className="country-marker-timer">
                        {formatTimeLeft(timeLeft)}
                        {voyageCount > 1 && (
                            <div className="country-marker-count">
                                {`${voyageCount}`}
                            </div>
                        )}
                    </div>
                )}
            </button>
        );
    });

    const availableShips = useMemo(() => {
        if (ships && ships.length > 0) {
            return ships.filter(ship => {
                const rentEndTime = new Date(ship.rent);
                const travelEndTime = new Date(ship.travel);
    
                // Не показывать корабль, который ты сдал в аренду
                const isRentedOut = ship.wallet_rent && ship.wallet_rent != window.wallet && rentEndTime > serverTime;
    
                const isTraveling = travelEndTime > serverTime;
                return ship.price_rent == 0 && !isRentedOut && !isTraveling;
            });
        }
        return [];
    }, [ships]);

    const nextShip = useCallback(() => {
        if (availableShips && availableShips.length > 0) {
            setSelectedShipIndex((prevIndex) => (prevIndex + 1) % availableShips.length);
        }
    }, [availableShips.length]);

    const prevShip = useCallback(() => {
        if (availableShips && availableShips.length > 0) {
            setSelectedShipIndex((prevIndex) => (prevIndex - 1 + availableShips.length) % availableShips.length);
        }
    }, [availableShips.length]);
   
    const currentCapacity = useMemo(() => {
        if (availableShips && availableShips.length > 0 && availableShips[selectedShipIndex]) {
            return getShipCapacity(availableShips[selectedShipIndex].type, availableShips[selectedShipIndex].lvl);
        }
        return 'N/A';
    }, [availableShips, selectedShipIndex]);

    const currentSpeed = () => {
        if (availableShips && availableShips.length > 0 && availableShips[selectedShipIndex]) {
            return getShipSpeed(availableShips[selectedShipIndex].type, availableShips[selectedShipIndex].lvl);
        } else {
            return getShipSpeed('Pioneer', 1);
        }
    }

    const handleContainerChange = useCallback((type, change) => {
        setSelectedContainers((prev) => {
            const newCount = Math.max(0, prev[type] + change);
            return {
                ...prev,
                [type]: newCount,
            };
        });
    }, []);

    const onIncrement = useCallback((type, count) => () => {
        if (totalSelectedContainers < currentCapacity && selectedContainers[type] < count) {
            handleContainerChange(type, 1);
        }
    }, [totalSelectedContainers, currentCapacity, selectedContainers, handleContainerChange]);
    
    const onDecrement = useCallback((type) => () => {
        handleContainerChange(type, -1);
    }, [handleContainerChange]);

    const StaticContainerImage = React.memo(({ src, alt }) => {
        return <img src={src} alt={alt} />;
    });

    const ContainerSelection = React.memo(({ type, count, selectedCount, onIncrement, onDecrement }) => {

        return (
            <div className="country-cont-item">
                <StaticContainerImage src={containerImages[type]} alt={type} />
                <span className="country-cont-item-amount">{count}</span>
                <button className="country-cont-item-minus" onClick={onDecrement}>–</button>
                <div className="country-cont-item-amount-selected">{selectedCount}</div>
                <button className="country-cont-item-plus" onClick={onIncrement}>+</button>
            </div>
        );
    });

    ////////////// ОТПРАВКА РЕЙСА /////////////
    const handleSend2 = (sendData) => {
        toVoyage(
            window.tgID,
            sendData.costFuel,
            sendData.container_to_voyage, 
            sendData.ship, 
            sendData.timer,
            sendData.country,
            sendData.xp,
            sendData.resources_voyage
        )
        .then(() => getMyVoyages(window.tgID))
        .then(responseMyVoyages => {
            setMyVoyages(responseMyVoyages);

            let voyage = null;
            for (let i = responseMyVoyages.length - 1; i >= 0; i--) {
                if (responseMyVoyages[i].country === sendData.country) {
                    voyage = responseMyVoyages[i];
                    break;
                }
            }

            setActiveVoyage(voyage);
            setAlert('voyage-sended');
    
            updateData();
            updateDataVoyages();
            setSendData(null);
            setShowModalInfo(true); // Открываем окно "Рейс отправлен"
            setIsSending(false); // Завершаем загрузку
            setShowModal(false); // Закрываем окно отправки
        })
        .catch(error => {
            setIsSending(false); // Завершаем загрузку в случае ошибки
            setSendData(null);
        });
    };

    const sendCancel = () => {
        setSendData(null);
        setIsSending(false); // Завершаем загрузку
    }

    const handleSend = async () => {
        setIsSending(true); // Начинаем загрузку
        const availableFuel = (window.resources.map((value, index) => value - window.resources_market[index]))[1];
        if (availableFuel >= selectedCountry.fuel) {

            if (selectedCountry.name == 'HODL, FUCK!') {
                const chatId = '@hodl_fuck';
                try {
                    const checkSubs = await checkSubscribe(window.tgID, chatId);
                    if (!checkSubs) {
                        setEventSubscribe(selectedCountry.name);
                        return;
                    }
                } catch (error) {
                    setAlert('error');
                    setIsSending(false);
                    return;
                }
            }

            // Количество топлива
            const costFuel = Array(14).fill(0);
            costFuel[1] = selectedCountry.fuel;

            // Контейнеры
            const container_to_voyage = Object.values(selectedContainers);

            // Время рейса
            const time = selectedCountry.timer * (1 - currentSpeed() / 100)*3600 // Это количество часов в секундах
            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time % 3600) / 60);
            const seconds = time % 60;
            const timer = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

            // Привезет ресурсов
            const resources_voyage = {};
            Object.values(selectedCountry.earning).forEach(item => {
                let minAmount, maxAmount;
                ({ minAmount, maxAmount} = calculateResourceAmounts(item, selectedContainers));

                const randomAmount = parseFloat((Math.random() * (maxAmount - minAmount) + minAmount).toFixed(2));
            
                resources_voyage[item.resourceId] = { min: minAmount, max: maxAmount, random: randomAmount};
            });


            // ПРОВЕРКА на СКЛАД
            const currentStoreLevel = config_store.store.find(store => store.lvl == window.lvl_store);
            const capacityResources = currentStoreLevel.capacity[0].resources;

            const totalResources = [...window.resources];
            for (const [resourceId, resourceData] of Object.entries(resources_voyage)) {
                const id = parseInt(resourceId, 10) - 1;
                totalResources[id] += resourceData.random;
            }

            const isCapacityExceeded = Object.keys(resources_voyage).some(resourceId => {
                const id = parseInt(resourceId, 10) - 1;
                return totalResources[id] > capacityResources[id];
            });

            const dataSend = {
                costFuel: costFuel,
                container_to_voyage: container_to_voyage, 
                ship: availableShips[selectedShipIndex].id, 
                timer: timer,
                country: selectedCountry.name,
                xp: selectedCountry.xp,
                resources_voyage: resources_voyage
            };

            setSendData(dataSend);


            if (isCapacityExceeded) {
                setFullStore(true);
            } else {
                if (selectedCountry.name == 'HODL, FUCK!') {
                    setEventQuestModal(selectedCountry.name);
                } else {
                    handleSend2(dataSend);
                }
            }
        } else {
            setAlert('no-fuel');
            setIsSending(false);
        }
    };

    const formatTime = (number) => {
        const hours = Math.floor(number);
        const minutes = Math.round((number - hours) * 60);
      
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      
        const timeString = `${formattedHours}:${formattedMinutes}`;
        return timeString;
    };

    useEffect(() => {
        let intervalId;
    
        const updateTempTerm = () => {
            if (selectedCountry && selectedCountry.name === "HODL, FUCK!") {
                const termStart = new Date(selectedCountry["term-start"]);
                const termEnd = new Date(selectedCountry["term-end"]);
    
                if (serverTime < termStart) {
                    // Если текущее время меньше term-start
                    const timeDifference = termStart - serverTime;
                    const totalMinutes = Math.floor(timeDifference / (1000 * 60));
                    const days = Math.floor(totalMinutes / (60 * 24));
                    const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
                    const minutes = totalMinutes % 60;
    
                    setTempTerm(`${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
                    setTermType('start'); // Изменяем состояние termType на 'start'
                } else {
                    // Если текущее время больше или равно term-start
                    const timeDifference = termEnd - serverTime;
    
                    if (timeDifference > 0) {
                        const totalMinutes = Math.floor(timeDifference / (1000 * 60));
                        const days = Math.floor(totalMinutes / (60 * 24));
                        const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
                        const minutes = totalMinutes % 60;
    
                        setTempTerm(`${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
                    } else {
                        setTempTerm("00h 00:00");
                    }
                    setTermType('end'); // Изменяем состояние termType на 'end'
                }
            }
        };
    
        updateTempTerm();
    
        if (selectedCountry && selectedCountry.name === "HODL, FUCK!") {
            intervalId = setInterval(updateTempTerm, 60000); // Обновляем каждую минуту
        }
    
        return () => clearInterval(intervalId);
    }, [selectedCountry]);

    useEffect(() => {  
        if (selectedCountry && selectedCountry.name === "HODL, FUCK!" && (window.event_view == null || (window.event_view && (Object.keys(window.event_view).length === 0 || !window.event_view[0])))) {
            setEventInfo(selectedCountry);
        }
    }, [selectedCountry]);

    return (
        <>
        {fullStore && <FullStoreModal fullStore={fullStore} setFullStore={setFullStore} handleSend2={handleSend2}
        sendData={sendData} sendCancel={sendCancel} countryName={selectedCountry.name} setEventQuestModal={setEventQuestModal}/>}

        {eventQuestModal && <EventQuestModal eventQuestModal={eventQuestModal} setEventQuestModal={setEventQuestModal} sendData={sendData} handleSend2={handleSend2}/>}
        {eventSubscribe ? <EventSubscribe eventSubscribe={eventSubscribe} setEventSubscribe={setEventSubscribe} setIsSending={setIsSending}/> : null}
        {eventInfo ? <EventInfo eventInfo={eventInfo} setEventInfo={setEventInfo} setNewEvent={setNewEvent}/> : null}
        {modalListVoyages ? <ModalList
            modalListVoyages={modalListVoyages}
            setModalListVoyages={setModalListVoyages}
            listSended={listSended}
            setActiveVoyage={setActiveVoyage}
            setShowModalInfo={setShowModalInfo}
        /> : null}
        <div className="voyage">
            {alert ? <Alert alert={alert} setAlert={setAlert}/> : null}
            <VoyageEnd onVoyagePage={true} refreshData={refreshData} setRefreshData={setRefreshData} setLVL={setLVL} setXP={setXP}/>
            <div className="map" ref={mapRef}>
                <img className="map-image" src={MapImage} alt="Map" ref={mapImgRef}/>
                {markerPositions && countries}
            </div>

            {showModalInfo && (<ModalInfo
                onClose={closeModalInfo}
                selectedCountry={activeVoyage ? config.counties.find(c => c.name === activeVoyage.country) : selectedCountry}
                currentSpeed = {currentSpeed()}
                ships = {ships}
                voyage={activeVoyage}
                tempTerm={tempTerm}
                termType={termType}
            />)}

            {showModal && (
                <div className="country-modal">
                    <div className="country-modal-header">
                        <div className="button-box-country">
                            <button className="back-button-coutry" onClick={closeModal}>
                                <img src={BackImage} alt="Back" />
                            </button>
                        </div>
                        <span className="country-modal-title">{t('go-voyage-title')}</span>
                        
                        {buttonVoyages ? (
                            <button className="sended-button" onClick={() => setModalListVoyages(true)}>{t('on-the-way')}: {listSended.length}</button>
                        ) : null}
                    </div>

                    <div className="country-modal-content">
                        {!dataLoaded ? (
                            <>
                            {/* <Skeleton width="94%" height="50vw" margin-top="3%" margin-left="3%" /> */}

                            <div className="loading-animation">
                                <svg className="spinner" strokeWidth="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                </svg>
                            </div> 
                            </>
                        ) : (
                            selectedCountry.name != 'Hamster rescue' ? (
                                <>
                                <div className="country-modal-content">
                                    <div className="country-box">
                                        <img className="country-img" src={countryImages[selectedCountry.name]} alt={selectedCountry.name} />
                                        <div className="country-name">{t(selectedCountry.name)}</div>
                                        <div className="country-timer">
                                            <img src={TimerImage} alt="Timer" />
                                            <span>{currentSpeed() == 0 ? formatTime(selectedCountry.timer) : formatTime(selectedCountry.timer-((selectedCountry.timer/100)*currentSpeed()))}</span>
                                        </div>
                                        <div className="country-xp">
                                            <img src={XpImage} alt="Xp" />
                                            <span>{selectedCountry.xp}</span>
                                        </div>
                                        <div className="country-resources">
                                            {selectedCountry.earning.map((earning, index) => {
                                                const { minAmount, maxAmount } = calculateResourceAmounts(earning, selectedContainers);
                                                const { minAmountInt, maxAmountInt } = calculateResourceAmountsInt(earning, selectedContainers);

                                                return (
                                                    <div key={index} className="country-resource-item">
                                                        <div className="country-resource-amount">
                                                            <span>{format(minAmount)}–{format(maxAmount)}</span>
                                                        </div>
                                                        <div className="country-resource-img-box">
                                                            <img src={resourceImages[earning.resourceId]} alt={`Resource ${earning.resourceId}`} />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {selectedCountry.name == 'HODL, FUCK!' ? (
                                            <>
                                                {termType === 'end' && <div className="country-temp-term">{tempTerm}</div>}
                                                <button className="country-temp-info" onClick={() => setEventInfo(selectedCountry)}>i</button>
                                                <div className="event-points">
                                                    <span>{t('points')}: {(window.event_points && window.event_points[0]) ? window.event_points[0] : 0}</span>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>

                                    {selectedCountry && (window.lvl >= selectedCountry['min-lvl'] ? 
                                        (selectedCountry.name === 'HODL, FUCK!' && termType === 'end') || selectedCountry.name !== 'HODL, FUCK!' ? 
                                            (selectedCountry.name === 'HODL, FUCK!' && tempTerm !== '00h 00:00') || selectedCountry.name !== 'HODL, FUCK!' ?
                                                (selectedCountry.name === 'HODL, FUCK!' && (window.event_voyages == null || (window.event_voyages && (Object.keys(window.event_voyages).length == 0 || window.event_voyages[0] != selectedCountry.voyages)))) || selectedCountry.name != 'HODL, FUCK!' ?
                                                    availableShips.length > 0 ? 
                                                        <>
                                                            <div className="country-ship-box">
                                                                <div className={`ship-slider ${availableShips.length === 1 ? 'single-ship' : availableShips.length === 2 ? 'two-ships' : 'three-or-more-ships'}`}>
                                                                    {availableShips.length === 1 ? (
                                                                        <button className="ship-item">
                                                                            {availableShips[selectedShipIndex] && (
                                                                                <>
                                                                                    <img src={shipImages[availableShips[selectedShipIndex].type]} alt="Selected Ship" />
                                                                                    <div className="ship-item-name">{availableShips[selectedShipIndex].name}</div>
                                                                                    <div className="ship-item-lvl">{t('lvl')} {availableShips[selectedShipIndex].lvl}</div>
                                                                                    <div className="ship-item-capacity">
                                                                                        {currentCapacity}
                                                                                        <img src={capacityImage} alt='Capacity'/>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    ) : availableShips.length === 2 ? (
                                                                        <>
                                                                            <button className="ship-item">
                                                                                {availableShips[selectedShipIndex] && (
                                                                                    <>
                                                                                        <img src={shipImages[availableShips[selectedShipIndex].type]} alt="Selected Ship" />
                                                                                        <div className="ship-item-name">{availableShips[selectedShipIndex].name}</div>
                                                                                        <div className="ship-item-lvl">{t('lvl')} {availableShips[selectedShipIndex].lvl}</div>
                                                                                        <div className="ship-item-capacity">
                                                                                            {currentCapacity}
                                                                                            <img src={capacityImage} alt='Capacity'/>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                            <button className="ship-item" onClick={nextShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                                                                                {availableShips[(selectedShipIndex + 1) % availableShips.length] && (
                                                                                    <>
                                                                                        <img src={shipImages[availableShips[(selectedShipIndex + 1) % availableShips.length].type]} alt="Next Ship" />
                                                                                        <div className="ship-item-name">{availableShips[(selectedShipIndex + 1) % availableShips.length].name}</div>
                                                                                        <div className="ship-item-lvl">{t('lvl')} {availableShips[(selectedShipIndex + 1) % availableShips.length].lvl}</div>
                                                                                        <div className="ship-item-capacity">
                                                                                            {getShipCapacity(availableShips[(selectedShipIndex + 1) % availableShips.length].type, availableShips[(selectedShipIndex + 1) % availableShips.length].lvl)}
                                                                                            <img src={capacityImage} alt='Capacity'/>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {availableShips.length > 1 && (
                                                                                <button className="ship-item" onClick={prevShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                                                                                    {availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length] && (
                                                                                        <>
                                                                                            <img src={shipImages[availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length].type]} alt="Previous Ship" />
                                                                                            <div className="ship-item-name">{availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length].name}</div>
                                                                                            <div className="ship-item-lvl">{t('lvl')} {availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length].lvl}</div>
                                                                                            <div className="ship-item-capacity">
                                                                                                {getShipCapacity(availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length].type, availableShips[(selectedShipIndex - 1 + availableShips.length) % availableShips.length].lvl)}
                                                                                                <img src={capacityImage} alt='Capacity'/>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </button>
                                                                            )}
                                                                            <button className="ship-item">
                                                                                {availableShips[selectedShipIndex] && (
                                                                                    <>
                                                                                        <img src={shipImages[availableShips[selectedShipIndex].type]} alt="Selected Ship" />
                                                                                        <div className="ship-item-name">{availableShips[selectedShipIndex].name}</div>
                                                                                        <div className="ship-item-lvl">{t('lvl')} {availableShips[selectedShipIndex].lvl}</div>
                                                                                        <div className="ship-item-capacity">
                                                                                            {currentCapacity}
                                                                                            <img src={capacityImage} alt='Capacity'/>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </button>
                                                                            {availableShips.length > 1 && (
                                                                                <button className="ship-item" onClick={nextShip} style={{ transform: 'scale(0.8)', opacity: 0.8 }}>
                                                                                    {availableShips[(selectedShipIndex + 1) % availableShips.length] && (
                                                                                        <>
                                                                                            <img src={shipImages[availableShips[(selectedShipIndex + 1) % availableShips.length].type]} alt="Next Ship" />
                                                                                            <div className="ship-item-name">{availableShips[(selectedShipIndex + 1) % availableShips.length].name}</div>
                                                                                            <div className="ship-item-lvl">{t('lvl')} {availableShips[(selectedShipIndex + 1) % availableShips.length].lvl}</div>
                                                                                            <div className="ship-item-capacity">
                                                                                                {getShipCapacity(availableShips[(selectedShipIndex + 1) % availableShips.length].type, availableShips[(selectedShipIndex + 1) % availableShips.length].lvl)}
                                                                                                <img src={capacityImage} alt='Capacity'/>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <div className="country-ship-capacity">
                                                                    <div className="country-ship-capacity-amount">
                                                                        {totalSelectedContainers}/{currentCapacity}
                                                                    </div>
                                                                    <div className="country-ship-capacity-img">
                                                                        <img src={capacityImage} alt='Capacity'/>
                                                                    </div>
                                                                </div>

                                                                <div className="country-ship-button-box">
                                                                    <div className="country-ship-button-fuel-box">
                                                                        {selectedCountry.fuel}
                                                                        <img src={Resource2Image} alt="Fuel" />
                                                                    </div> 
                                                                    <button 
                                                                        className="country-ship-button" 
                                                                        onClick={handleSend} 
                                                                        disabled={totalSelectedContainers === 0 || isSending}
                                                                        style={{ 
                                                                            pointerEvents: (totalSelectedContainers === 0 || isSending) ? 'none' : 'auto',
                                                                            opacity: (totalSelectedContainers === 0 || isSending) ? 0.6 : 1
                                                                        }}
                                                                    >
                                                                        {isSending ? (
                                                                            <div className="loading-animation-send">
                                                                                <svg className="spinner" strokeWidth="65px" height="80%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                                                                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                                                                                </svg>
                                                                            </div>
                                                                        ) : (
                                                                            t('send')
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="country-cont-box">
                                                                {[
                                                                    { type: 'blue-cont', count: window.cont_blue - window.voyage_conts[0] },
                                                                    { type: 'red-cont', count: window.cont_red - window.voyage_conts[1] },
                                                                    { type: 'green-cont', count: window.cont_green - window.voyage_conts[2] },
                                                                    { type: 'bronze-cont', count: window.cont_bronze - window.voyage_conts[3] },
                                                                    { type: 'silver-cont', count: window.cont_silver - window.voyage_conts[4] },
                                                                    { type: 'gold-cont', count: window.cont_gold - window.voyage_conts[5] }
                                                                ].map((cont, index) => (
                                                                    <ContainerSelection
                                                                        key={index}
                                                                        type={cont.type}
                                                                        count={cont.count}
                                                                        selectedCount={selectedContainers[cont.type]}
                                                                        onIncrement={onIncrement(cont.type, cont.count)}
                                                                        onDecrement={onDecrement(cont.type)}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </> 
                                                        : 
                                                        <div className="not-ships">
                                                            <span>{t('not-ships')}</span>
                                                        </div>
                                                    : 
                                                    <div className="event-done">
                                                        <span>{t('event1')}</span>
                                                    </div>
                                                : 
                                                <div className="event-ended">
                                                    <span>{t('event2')}</span>
                                                </div>   
                                            : 
                                            <div className="event-not-started">
                                                <span>{t('event3')}</span>
                                                <span>{tempTerm}</span>
                                            </div>    
                                        : 
                                        <div className="country-low-lvl">
                                            {t('low-lvl')}
                                            <div className="country-need-lvl">{selectedCountry['min-lvl']}</div>
                                        </div>
                                    )}
                                </div>
                                </>
                            ) : (
                                < Temp2Anounce/>
                            )
                        )}
                    </div>
                    
                    
                </div>
            )}
        </div>
        </>
    );
};

export default Voyage;